import React from 'react';
// import Logo from "./Logo"
// import Find from "./Find"

export default class Find extends React.Component {

    render() {
        return(            
            <div className="find input">
                <div className="circle"><i className="icon search "></i></div>
                <input className="" type="text" placeholder="Поиск по часто задаваемым вопросам"></input>   
            </div>    
            
        )
    }
}