import React from "react";
import { text } from "../../language/users";
import UsersTable from "../../components/Users/UsersTable";
import { getUsers } from "../../api/admin";
import Paginator from "../../components/uikit/Paginator";

import { connect } from "react-redux";
import { categoryEdit } from "../../api/category";
const countRowOnPage = 10;

class AExperts extends React.Component {
  state = {
    users: [],
    userTypeId: 2,
    pageCount: 0,
    currentPage: 1,
  };

  componentDidMount() {
    this.setUsers();
    document.title = "Эксперты";
  }

  setUsers = () => {
    let users = [];

    getUsers({ userTypeId: 2 }, ({ results = [] }) => {
      users = results.reverse();
      let pageCount;
      if (users.length !== 0)
        pageCount = Math.ceil(users.length / countRowOnPage);
      console.log(users);

      this.props.setUsers(users);
      this.setState({ pageCount });
    });
  };

  changePage = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { currentPage, pageCount } = this.state;
    const { users } = this.props;
    const currentPageIndex = this.state.currentPage - 1;

    const showedUsers = users.slice(
      currentPageIndex * countRowOnPage,
      currentPageIndex * countRowOnPage + countRowOnPage
    );
    return (
      <div className="Users">
        <h1 className="m-title without-border">Эксперты</h1>
        <UsersTable users={showedUsers} currentPage={currentPage}></UsersTable>
        <Paginator
          pageCount={pageCount}
          currentPage={currentPage}
          changePage={this.changePage}
        ></Paginator>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (data) => dispatch({ type: "SET_USERS", payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AExperts);
