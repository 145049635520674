import React from "react";
const HelpTip = ({ title, text, index }) => {
  return (
    <div
      className="card mobile-margin m-m-t-15"
      style={{ width: "-webkit-fill-available" }}
    >
      <div className="card-header" id="headingThree">
        <h5 className="mb-0">
          <button
            className="btn btn-link collapsed"
            type="button"
            data-toggle="collapse"
            data-target={"#collapse" + index}
            aria-expanded="false"
            aria-controls={"collapse" + index}
          >
            <h5>{title}</h5>
            <div className="caret-down"></div>
          </button>
        </h5>
      </div>
      <div
        id={"collapse" + index}
        className="collapse"
        aria-labelledby={"heading" + index}
        data-parent="#accordion"
      >
        <div className="card-body">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};
export default HelpTip;
