import React from "react";

export const NormItem = ({ norm, isExpert, isEditMode, actualize }) => {
  const { title, actual_text, is_actual, status, actual_date, uuid } = norm;
  if (isExpert == true) {
    return (
      <div className="norm">
        <div className="norm__title">{title}</div>
        {isExpert && is_actual == false ? (
          <>
            <div className="norm__actual-text">
              Актуальный текст нормы: {actual_text}
            </div>
            <div
              onClick={() => actualize(uuid, actual_date)}
              className="norm__actuailize"
            >
              Актуализировать
            </div>
          </>
        ) : null}
      </div>
    );
  }

  if (isExpert == false && typeof norm != "object") {
    return (
      <div className="norm">
        <div className="norm__title">{norm}</div>
      </div>
    );
  }
  return null;
};
