import $ from "jquery";
import { store } from "../App";

// Получение списка пользователей
export function getUsers(data, success) {
  var url = "https://consult.ab-dpo.ru/api/admin/listUsers/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    xhrFields: { withCredentials: true },

    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    async: false,
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

// Изменение пользователя
export function editUser(data, success) {
  var url = "https://consult.ab-dpo.ru/api/admin/editUser/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    xhrFields: { withCredentials: true },

    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

// Добавление пользователя
export function addUser(data, success) {
  var url = "https://consult.ab-dpo.ru/api/admin/addUser/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    xhrFields: { withCredentials: true },

    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}
// Удаление пользователя
export function deleteUser(data, success) {
  var url = "https://consult.ab-dpo.ru/api/admin/deleteUser/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    xhrFields: { withCredentials: true },

    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

// Получение списка типов пользователей
export function listUserTypes(data, success) {
  var url = "https://consult.ab-dpo.ru/api/admin/listUserTypes/";
  $.ajax({
    url: url,
    type: "post",
    crossDomain: true,
    xhrFields: { withCredentials: true },

    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

function getParams(params) {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}
