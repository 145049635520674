import React from 'react'
import MessageBlock from '../../components/uikit/MessageBlock/MessageBlock'
import {
  dialogSendMessage,
  dialogGet,
  getFormattedDialogs,
  dialogAcceptMessage,
  dialogGetQuotedMessage,
  dialogList
} from '../../api/dialog'
import { sendMessage, createFAQButton } from '../../language/Dialogs'
import CMessageBlock from '../../components/uikit/MessageBlock/CMessageBlock'
import FormForQuotedMessage from '../../components/FormForQuotedMessage'
import { Link } from 'react-router-dom'
import { categoryList } from '../../api/category'
import { getMessagesTimer } from '../../constants/Constants'

const ButtonBlock = ({ onSend, id, closeDialogF }) => {
  return (
    <div className='btn-block'>
      <button onClick={onSend} className='dialog-btn btn-send' id={id}>
        <div className='letter'></div>
        {sendMessage}
      </button>
    </div>
  )
}

export default class QForCheck extends React.Component {
  state = {
    id: null,
    msgs: [],
    response: '',
    isClose: false,
    status: null,
    quotedDialogId: null,
    quotedMessage: null,
    category: '',
    isShow: false
  }
  componentDidMount () {
    const { id, category } = this.props.match.params
    this.setState({ id })
    this.loadMessages(id)
    this.getDialogInfo(id)
    document.title = 'Вопросы на проверку от экспертов'
    this.loadCategories(category.split('_').join(' '))
    const { location } = this.props
    if (location)
      if (location.state)
        this.setState({ quotedDialogId: this.props.location.state.qDialogId })
    setInterval(() => this.loadMessages(id), getMessagesTimer)
  }
  loadCategories = (defaultCategory = null) => {
    categoryList(({ results = [] }) => {
      results.map((category, index) => {
        const { title } = category
        let isDefaultCategory = title === defaultCategory
        console.log(defaultCategory, title)
        if (isDefaultCategory === true) {
          console.log(category)
          this.setState({ category })
        }
      })
    })
  }
  getDialogInfo = async id => {
    let dialog
    dialogList({ dialogId: id }, results => {
      console.log('РЕЗУЛЬТАААТ', results)
      // dialog = results[0]
      if (typeof dialog !== 'undefined') {
        console.log(dialog)

        const {
          isClosed,
          status = null,
          dialogId = null,
          faq = [],
          userRating = 0,
          userRatingComment = '',
          category
        } = dialog
        console.log('dialog', dialog)

        this.setState({
          isClose: isClosed,
          dialogId,
          faq,
          userRating,
          userRatingComment,
          category
        })
      }
    })
  }

  loadMessages = id => {
    dialogGet({ dialogId: id }, ({ results = [] }) => {
      this.setState({ msgs: results })
    })
  }

  onSend = e => {
    const { id, response } = this.state
    const isAnswer = this.state.msgs.length === 1 ? true : false
    dialogSendMessage(
      {
        dialogId: id,
        text: response,
        isAnswer: isAnswer
      },
      data => {
        this.loadMessages(id)
        console.log(data)
      }
    )
    this.setState({ response: '' })
  }
  handleChange = e => {
    this.setState({ response: e.target.value })
  }
  acceptMessage (messageId) {
    dialogAcceptMessage({ isAccepted: true, messageId }, () => {
      this.loadMessages(this.state.id)
    })
  }

  chainMessageToDialog = msg => {
    this.setState({ quotedMessage: msg, isShow: true })
  }
  onSendQuote = text => {
    const { id, quotedMessage, category, quotedDialogId } = this.state
    const quotedMessageId = quotedMessage.id

    console.log('категория', category)

    // КОСТЫЛЬ на диалог ID, который должен быть в возвращаемых параметрах диалога
    dialogSendMessage(
      {
        // dialogId: parseInt(quotedDialogId),
        quotedDialogId: id,
        text,
        quotedMessageId,
        categoryId: category.id
      },
      ({ result }) => {
        console.log(result)

        if (result !== false) {
          dialogGetQuotedMessage({ messageId: result }, data => {
            console.log(data)
          })
          this.loadMessages(id)
          this.getDialogInfo(id)
        }
        return
      }
    )
  }

  render () {
    const { msgs, status, quotedMessage, isShow } = this.state
    console.log(msgs)
    const { id, category } = this.props.match.params
    return (
      <div className='Dialog'>
        <Link
          className='DialogTitle h1 darkblue m-title without-border'
          to={{
            pathname: '/check/'
          }}
        >
          <div className='caret-down'></div>
          <div>Ответы на проверку</div>
        </Link>
        <div className='Messages'>
          {msgs.map((msg, index) => {
            return (
              <CMessageBlock
                key={index}
                msg={msg}
                accept={() => this.acceptMessage(msg.id)}
                chain={() => this.chainMessageToDialog(msg)}
              ></CMessageBlock>
            )
          })}
        </div>
        {isShow === false ? null : (
          <FormForQuotedMessage
            isShow={true}
            to={'/dialog/'}
            quotedMessage={quotedMessage}
            onSend={this.onSendQuote}
          ></FormForQuotedMessage>
        )}
      </div>
    )
  }
}
