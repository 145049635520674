import { combineReducers } from 'redux'

import users from './users'
import dialogs from './dialogs'
import account_type from './account_type'
import input_filter from './input_filter'
import filters from './filters'
import preloader from './preloader'

export default combineReducers({
  users,
  dialogs,
  account_type,
  filters,
  input_filter,
  preloader
})
