import React, { useState } from 'react'
import Title from './Title'
import Select from 'react-select'
import { categoryAdd, categoryEdit } from '../../api/category'
import ClickOutside from './ClickOutside'

const CategoryList = ({ categories = [] }) => {
  return (
    <div className='CategoryList'>
      {categories.map((category, index) => {
        const { value, label } = category
        return (
          <ListElement index={index} value={value} label={label}></ListElement>
        )
      })}
    </div>
  )
}
const ListElement = ({ value, label, index }) => {
  const [text, input] = useState(label)
  const [isEditable, changeEditable] = useState(false)
  const [bufName, setBufName] = useState(label)

  return (
    <ClickOutside
      onClickOutside={() => {
        input(bufName)
        changeEditable(false)
      }}
    >
      <div key={index} className='ListElement'>
        {isEditable === false ? (
          <>
            <div>{text}</div>
            <div className='iconBlock'>
              <div
                className='edit pointer'
                onClick={() => changeEditable(!isEditable)}
              ></div>
              {/* <div
                className='cancel pointer'
                onClick={() => {
                  category({ id: value, title: text }, result => {
                    if (result) changeEditable(false)
                  })
                }}
              ></div> */}
            </div>
          </>
        ) : (
          <>
            <input
              placeholder={'Введите название категории'}
              type={'text'}
              value={text}
              onChange={e => input(e.target.value)}
            ></input>
            <div
              className='good pointer'
              onClick={() => {
                categoryEdit({ id: value, title: text }, result => {
                  if (result) {
                    changeEditable(false)
                    setBufName(text)
                  }
                })
              }}
            ></div>
          </>
        )}
      </div>
    </ClickOutside>
  )
}
const AddCategoryForm = ({}) => {
  const [newCategory, inputCategory] = useState('')
  return (
    <form
      className='AddCategoryForm'
      onSubmit={() => {
        categoryAdd({ title: newCategory }, result => {
          if (result) alert('Категория добавлена')
        })
      }}
    >
      <input
        placeholder={'Введите название категории'}
        type={'text'}
        value={newCategory}
        onChange={e => inputCategory(e.target.value)}
      ></input>
      <input type={'submit'} value={'Добавить категорию'}></input>
    </form>
  )
}
const EditCategory = ({ categories }) => {
  return (
    <div className='EditCategory'>
      <Title title='Редактирование категорий'></Title>
      <CategoryList categories={categories}></CategoryList>
      <AddCategoryForm></AddCategoryForm>
    </div>
  )
}

export default EditCategory
