import React from "react";
const UserRatingBlock = ({
  userRating = null,
  userRatingComment = null,
  showButtons = false,
}) => {
  return (
    <>
      {userRatingComment === null || userRatingComment === "" ? null : (
        <div
          className="comment mobile-padding"
          style={{ display: "flex", marginTop: 10 }}
        >
          <div className="gray" style={{ width: 110 }}>
            Комментарий{" "}
          </div>
          <span>{userRatingComment}</span>
        </div>
      )}
      {userRating === null || userRating === 0 ? null : (
        <div className="starsBlock mobile-padding">
          <span className="gray" style={{ width: 110 }}>
            Оценка
          </span>
          <Stars starsCount={userRating}></Stars>
        </div>
      )}
    </>
  );
};
const Stars = ({ starsCount }) => {
  let stars = new Array(5);
  for (let i = 0; i < 5; i++) {
    if (i < starsCount) {
      stars[i] = true;
    } else stars[i] = false;
  }
  return stars.map((star, index) => {
    return (
      <div
        key={index}
        className={star === true ? "Star star-filled" : "Star star"}
      ></div>
    );
  });
};
export default UserRatingBlock;
