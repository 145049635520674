import React from "react";
import { getFormattedDialogs } from "../../api/dialog";
import Table from "../../components/uikit/DialogTable/Table";
import { categoryEdit } from "../../api/category";
import EmptyMessage from "../../components/uikit/EmptyMessage";
import { connect } from "react-redux";
import FiltersBlock from "../../components/uikit/FiltersBlock";
import { getMessagesTimer } from "../../constants/Constants";

class CQuestions extends React.Component {
  state = {
    questions: [],
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.filters !== this.props.filters) {
      this.loadDialogs();
    }
  }
  loadDialogs = () => {
    const newFilter = this.props.filters;
    newFilter.available = true;
    newFilter.isController = false;
    newFilter.someoneElse = true;
    newFilter.orderByNew = false;

    getFormattedDialogs(newFilter, (data) => {
      console.log(data);
      if (data && data !== false)
        data.map((question, index) => {
          question.status = null;
        });
      this.setState({ questions: data });
    });
  };
  componentDidMount() {
    this.loadDialogs();
    setInterval(() => this.loadDialogs(), getMessagesTimer);
    document.title = "Вопросы пользователей";
  }
  render() {
    // console.log(this.state.questions.category.title);
    const { questions } = this.state;
    return (
      <div className="Questions">
        <h1 className="m-title">Вопросы пользователей</h1>
        <FiltersBlock questions={true}></FiltersBlock>
        {questions.length === 0 ? (
          <EmptyMessage
            message={"Нет вопросов от пользователей"}
          ></EmptyMessage>
        ) : (
          <Table
            to="/question/"
            // category={}
            dataLength={questions.length}
            headers={[
              { title: "", tip: null, colSpan: 1 },
              { title: "Вопрос", tip: null, colSpan: 1 },
              { title: "Категория", tip: null, colSpan: 1 },
              { title: "", tip: null, colSpan: 1 },
            ]}
            data={questions}
          ></Table>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CQuestions);
