import React from "react";
import { Link } from "react-router-dom";
const FaqElement = ({ faq = { question: "" }, id, category, i }) => {
  console.log("Строка из таблицы faq", faq, id, category);

  const { question } = faq;

  return (
    <p className="FaqElement mobile-padding">
      <span className="gray">Вопрос </span>
      <Link to={"/faq/" + category + "/" + id} key={i}>
        {question}
      </Link>
    </p>
  );
};

export default FaqElement;
