export const formatTime = (time = "") => {
  time = time.split(" ");
  var date = time[0];
  time = time[1];

  date = reverseSting(date, ".");
  console.log("New Date&Time", date, time);
  return { date, time };
};

export const reverseSting = (string = "", separator = " ") => {
  string = string.split(separator);
  let length = string.length;
  let finalString = "";
  for (let i = length - 1; i >= 0; i--) {
    if (i !== 0) finalString += string[i] + separator;
    else finalString += string[i];
  }

  return finalString;
};
