import React from "react";

import "./style.scss";

export const Input = ({
  onChange = () => {},
  value = "",
  placeholder = "",
  onBlur = () => {},
  type = "text",
}) => {
  const handleChange = (e) => onChange(e.target.value);

  const handleBlur = (e) => onBlur(e.target.value);

  return (
    <input
      onBlur={handleBlur}
      onChange={handleChange}
      type={type}
      placeholder={placeholder}
      value={value}
    ></input>
  );
};
