export const darkblue = "#18567e";
export const accentLight = "#3498db";
export const accentDark = "#18567e";
export const text = "#1f1f1f";
export const white = "#fff";
export const gray = "#c4c4c4";
export const hover_gray = "#f8f8f8";
export const red = "#e74c3c";
export const yellow = "#ecc31f";
export const texthover = "#f5f9ff";
export const green = "#1ba254";

export const orange = "#ff8100";
export const lightblue = "#3bb1ff";

export const getMessagesTimer = 30000;

export const hoursForAnswer = 48;
export const msInHour = 3600000;

export const counters = {
  user: { id: "user-unreadable", isImportant: false },
  qAll: { id: "all-questions", isImportant: false },
  qOverdue: { id: "overdue-questions", isImportant: true },
  qAllController: { id: "all-questions-controller", isImportant: false },
  qOverdueController: { id: "overdue-questions-controller", isImportant: true },
  expertAll: { id: "expert-all", isImportant: false },
  expertOverdue: { id: "expert-overdue", isImportant: true },
  controllerAllOthers: { id: "controller-all-others", isImportant: false },
  controllerOverdueOthers: {
    id: "controller-overdue-others",
    isImportant: true,
  },
  controllerAllForCheck: { id: "controller-all-for-check", isImportant: false },
  controllerOverdueForCheck: {
    id: "controller-overdue-for-check",
    isImportant: true,
  },
};

export function isValid(obj) {
  if (typeof obj !== "undefined" && obj != null) {
    return true;
  } else {
    return false;
  }
}
