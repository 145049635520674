import React from "react";

import { Link } from "react-router-dom";

const Button = ({ to, text, className }) => {
  return (
    <Link to={to}>
      <button className="button col-12">
        {/* <Link to={to}>{text}</Link> */}
        <div className={className}></div>
        <span>{text}</span>
      </button>
    </Link>
  );
};
export default Button;
