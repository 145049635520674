import React from 'react'

const Title = ({ title = '' }) => {
  return (
    <div className='Title'>
      <h1>{title}</h1>
    </div>
  )
}
export default Title
