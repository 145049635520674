import $ from "jquery";
import { store } from "../App";
var url = "https://consult.ab-dpo.ru/api";
let skkAPI = "http://skk.nord.su/api/";
let key = "?public_key=VkHVBDyQTIL03NfUnUz4zNhpexfubjqAmBzsrJkE";

// Основное тело запросов
const defaultFail = (err) => console.log(err);

function commonRequestSKK(
  method = "",
  data = { dateTo: "", dateFrom: "", num_part: "" },
  success,
  type = "post"
) {
  let methodURL = skkAPI + method + key;

  $.ajax({
    url: methodURL,
    type,
    data: getParams(data),
    crossDomain: true,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: {
      withCredentials: true,
    },

    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      defaultFail(err);
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

const ajaxBody = (methodUrl, data, success = () => {}, fail = defaultFail) => {
  return {
    url: methodUrl,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: { withCredentials: true },

    async: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    // async: false,
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      fail(err);
      store.dispatch({ type: "SET_FAIL" });
    },
  };
};

function getParams(obj) {
  return Object.keys(obj)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    })
    .join("&");
}

// Полуечение главных частей нормы
export function getAllNorms(
  data = { dateTo: "", dateFrom: "", num_part: "" },
  success
) {
  let method = "main_part/read";
  commonRequestSKK(method, data, success);
}

// Получение доп. частей нормы
export function getAdditionalPartsForNorm(
  data = { main_part_uuid: "" },
  success
) {
  let method = "additional_part/read";
  commonRequestSKK(method, data, success);
}
