import React from "react";
import animateScrollTo from "animated-scroll-to";
const Paginator = ({
  currentPage = 0,
  pageCount = 0,
  changePage = () => {}
}) => {
  var pages = [];
  if (!isNaN(pageCount)) pages = new Array(pageCount);
  for (var i = 0; i < pages.length; i++) {
    pages[i] = i + 1;
  }

  return (
    <div className="Paginator mobile-padding">
      {pages.map((page, index) => {
        return (
          <PaginatorElement
            key={index}
            page={page}
            changePage={changePage}
            isCurrent={currentPage === page}
          ></PaginatorElement>
        );
      })}
    </div>
  );
};

const PaginatorElement = ({ page, changePage, isCurrent }) => {
  return (
    <div
      className="PaginatorElement"
      style={{
        backgroundColor: isCurrent ? "#18567E" : "#fff",
        color: isCurrent ? "#fff" : "#333"
      }}
      onClick={() => {
        animateScrollTo(0, {
          speed: 50,
          minDuration: 400,
          easing: t => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
          }
        });
        changePage(page);
      }}
    >
      {page}
    </div>
  );
};
export default Paginator;
