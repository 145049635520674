import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div>
          <div className="col-12  f-col">
            <div className="row f-el f-title">
              {" "}
              <div className="settings"></div>Служба поддержки
            </div>
            <a href="tel:88005509590" className="row f-el">
              8 800 550 95 90
            </a>
          </div>
          <div className="col-12 f-col ">
            <div className="row f-el f-title">
              <div className="letter"></div> Почта
            </div>
            {/* <div className="row d-flex d-lg-none">
              <div className="col-12 f-el ">Москва - info@ab-dpo.ru</div>
              <div className="col-12 f-el ">Нижний Новгород - nn@ab-dpo.ru</div>
              <div className="col-12 f-el ">Иваново - ivanovo@ab-dpo.ru</div>
            </div> */}
            <div className="row d-flex">
              <div className="col-12 f-el a-l" style={{ justifyContent: "center" }}>
                info@ab-dpo.ru
              </div>
            </div>
          </div>
          <div className="col-12  f-col">
            <a href="https://ab-dpo.ru" className="row logotype">
              <div className="logo"></div>
              {/* Академия безопасности */}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
