import React, { useState } from "react";
import MessageBlock from "../../components/uikit/MessageBlock/MessageBlock";
import {
  dialogSendMessage,
  dialogGet,
  getFormattedDialogs,
} from "../../api/dialog";
import { sendMessage, createFAQButton } from "../../language/Dialogs";

import { Link } from "react-router-dom";
import EMessageBlock from "../../components/uikit/MessageBlock/EMessageBlock";
import FaqElement from "../../components/uikit/FaqElement";
import { faqGet } from "../../api/faq";
import UserRatingBlock from "../../components/UserRatingBlock";
import TextAreaBlock from "../../components/uikit/MessageBlock/TextAreaBlock";
import { darkblue, white, getMessagesTimer } from "../../constants/Constants";

const ButtonBlock = ({ onSend, id, closeDialogF }) => {
  return (
    <div className="btn-block mobile-padding mobile-w-100">
      <button
        onClick={onSend}
        className="dialog-btn btn-send mobile-w-100"
        id={id}
      >
        <div className="letter"></div>
        {sendMessage}
      </button>
    </div>
  );
};

export default class EDialog extends React.Component {
  state = {
    id: null,
    msgs: [],
    response: "",
    isClose: false,
    status: null,
    faqs: [],
    category: "",
    faq: [],
    userRating: null,
    userRatingComment: null,
    firstMessageUserType: null,
    isAnswer: false,
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;
    this.setState({ id, category });
    this.loadMessages(id);
    this.getDialogInfo(id);
    setInterval(() => {
      this.loadMessages(id);
      this.getDialogInfo(id);
    }, getMessagesTimer);

    document.title = "Мои диалоги";
  }

  getDialogInfo = async (id) => {
    getFormattedDialogs({ dialogId: id }, (results = []) => {
      const dialog = results.find((dialog, index) => {
        return dialog.id == id;
      });
      if (typeof dialog !== "undefined") {
        const {
          isClosed,
          status,
          faq,
          category,
          userRating,
          userRatingComment,
        } = dialog;
        this.setState({
          isClose: isClosed,
          staus: status.status,
          faq,
          userRating,
          userRatingComment,
        });
        let faqs = [];
        faq.map((fid, index) => {
          faqGet({ id: fid, category: category.id }, ({ result }) => {
            if (result !== false) {
              faqs.push(result);
              this.setState({ faqs });
            }
          });
        });
        console.log("fGet", faqs);
      }
    });
  };

  loadMessages = (id) => {
    dialogGet({ dialogId: id }, ({ results = [] }) => {
      this.setState({ msgs: results });
      if (results.length != 0 && results) {
        let firstMessageUserType = results[0].fromUserType;
        this.setState({ firstMessageUserType });
      }
      console.log(results);
    });
  };
  onSend = (e) => {
    const { id, response, firstMessageUserType, isAnswer } = this.state;

    if (firstMessageUserType == 3)
      dialogSendMessage(
        {
          dialogId: id,
          text: response,
          isAnswer: isAnswer,
          acceptMessage: true,
        },
        (data) => {
          this.loadMessages(id);
          console.log(data);
        }
      );
    else
      dialogSendMessage(
        {
          dialogId: id,
          text: response,
          isAnswer: isAnswer,
        },
        (data) => {
          this.loadMessages(id);
          console.log(data);
        }
      );
    this.setState({ response: "" });
  };
  handleChange = (e) => {
    let { value } = e.target;
    const { response } = this.state;
    let newStrLength = response.length + value.length;
    if (newStrLength > 10000) {
      value = value.substr(0, 10000);
    }
    this.setState({ response: value });
  };
  createFAQ = () => {};
  render() {
    const {
      msgs,
      status,
      category,
      faqs,
      faq,
      response,
      isClose,
      userRating,
      userRatingComment,
      firstMessageUserType,
      isAnswer,
    } = this.state;
    const { id } = this.props.match.params;
    return (
      <div className="Dialog">
        <h1 className="DialogTitle m-title without-border mobile-padding ellipsis">
          {typeof msgs[0] === "undefined" ? null : <>{msgs[0].text}</>}
        </h1>
        <div className="Messages">
          {msgs.map((msg, index) => {
            return <EMessageBlock key={index} msg={msg}></EMessageBlock>;
          })}
        </div>
        <div style={{ marginBottom: 20 }}>
          <UserRatingBlock
            userRating={userRating}
            userRatingComment={userRatingComment}
          ></UserRatingBlock>
        </div>
        {this.state.isClose === true ? (
          firstMessageUserType == 3 ? (
            <div className="dialogClosed">
              <div className="check"></div>
              <p>Диалог закрыт</p>
            </div>
          ) : (
            <div className="mobile-w-100" style={{ textAlign: "center" }}>
              <Link
                className="FAQ-button mobile-w-100 mobile-margin"
                to={"/createFAQ/" + id}
              >
                {createFAQButton}
              </Link>
            </div>
          )
        ) : (
          <>
            <TextAreaBlock
              onSend={this.onSend}
              handleChange={this.handleChange}
              value={response}
              closeDialog={this.closeDialogF}
              id={id}
            ></TextAreaBlock>
            {firstMessageUserType === 1 ? (
              <div
                className="checkbox mobile-padding"
                onClick={() => {
                  this.setState({ isAnswer: !isAnswer });
                }}
              >
                <div
                  className="block"
                  style={{
                    backgroundColor: isAnswer == true ? darkblue : white,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: isAnswer == true ? white : "transparent",
                    }}
                    className="check"
                  ></div>
                </div>
                <div className="text">Ответ является окончательным</div>
              </div>
            ) : null}
          </>
        )}

        {faq.length !== 0 || !faq || faq === null ? (
          <>
            <h2 className="AnotherFAQHeader without-border m-title ">
              Чистовые вопросы по данному диалогу
            </h2>
            <div className="AnotherFAQ">
              {faqs.map((FAAAQ, index) => {
                return (
                  <FaqElement
                    key={index}
                    faq={FAAAQ}
                    category={category}
                    id={faq[index]}
                    i={index}
                  ></FaqElement>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}
