import React from "react";
import { Link } from "react-router-dom";
import { dialogGet, dialogSendMessage, takeQuestion } from "../../api/dialog";
import MessageBlock from "../../components/uikit/MessageBlock/MessageBlock";
import { text } from "../../language/newQ";
import DialogHeader from "../../components/uikit/DialogHeader";

export const Button = ({ id, onClick, category }) => {
  const to = "/dialog/" + category + "/" + id;
  return (
    <div className="btn-block mobile-padding">
      <Link
        onClick={(e) => {
          e.preventDefault();
          onClick(to);
        }}
        style={{ marginTop: 10 }}
        className="dialog-btn btn-send mobile-w-100"
        to={to}
      >
        Взять вопрос
      </Link>
    </div>
  );
};

export default class Question extends React.Component {
  state = {
    id: null,
    category: "",
    msg: { text: "" },
    response:
      "Здравствуйте, Ваш вопрос принят на рассмотрение экспертом. В течение 48 часов вы получите на него ответ. Если хотите получить ответ быстрее воспользуйтесь услугой 'Быстрый ответ', с уважением администрация Академии Безопасности",
    isErrorLoad: false,
  };
  setTitle = (title) => {
    document.title = title;
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;
    this.setState({ id, category });
    this.loadDialog(id);
    this.setTitle("Вопросы пользователей");
  }

  loadDialog = (dialogId) => {
    dialogGet({ dialogId }, this.loadDialogSuccess);
  };

  loadDialogSuccess = ({ results }) => {
    if (results) {
      this.setState({ msg: results[0] });
      return;
    }

    if (typeof results == "undefined") {
      this.setState({ isErrorLoad: true });
    }
  };

  onSend = (to) => {
    const { id, response } = this.state;
    takeQuestion(
      {
        dialogId: id,
      },
      (data) => {
        document.location.replace(to);
      }
    );
  };

  handleChange = (e) => {
    this.setState({ response: e.target.value });
  };
  render() {
    const { msg, category, id, isErrorLoad } = this.state;

    return (
      <div className="Question">
        {isErrorLoad ? (
          <h1>
            Похоже, данный вопрос взял другой эксперт.
            <br />{" "}
            <div className="d-flex">
              Вы можете выбрать<Link to="/question">&nbsp;другой вопрос.</Link>
            </div>
          </h1>
        ) : (
          <>
            <DialogHeader title={msg.text} category={category}></DialogHeader>
            {typeof msg !== "undefined" ? (
              <MessageBlock msg={msg}></MessageBlock>
            ) : null}
            <Button onClick={this.onSend} id={id} category={category}></Button>
          </>
        )}
      </div>
    );
  }
}
