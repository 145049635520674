import React from "react";
import UserRow from "./UserRow";

const UsersTypes = [
  "Пользователь",
  "Эксперт",
  "Контроллер",
  "Администратор",
  "Оператор",
];
const TableHead = () => {
  const headers = ["Email", "ФИО", "Тип", "", ""];
  return (
    <thead>
      <tr>
        {headers.map((header, index) => {
          return <th key={index}>{header}</th>;
        })}
        {/* <td className="index col-1">{headers[0]}</td>
        <td className="index col-3">{headers[1]}</td>
        <td className="index col-2">{headers[2]}</td>
        <td className="index col-3">{headers[3]}</td>
        <td className="index col-2">{headers[4]}</td> */}
      </tr>
    </thead>
  );
};

const UsersTable = ({ users = [] }) => {
  return (
    <table className="UsersTable">
      {/* <TableHead /> */}
      <tbody>
        {users.map((user, index) => {
          const {
            id,
            email,
            firstName,
            nickname,
            userTypeId,
            isCertified,
            isDeleted,
            categories,
          } = user;
          return (
            <UserRow
              key={id}
              index={index + 1}
              id={id}
              email={email}
              categories={categories}
              firstName={firstName}
              nickname={nickname}
              userTypeId={userTypeId}
              isCertified={isCertified}
              isDeleted={isDeleted}
              categories={categories}
            ></UserRow>
          );
        })}
      </tbody>
    </table>
  );
};
export default UsersTable;
