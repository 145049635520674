import React from "react";
import { text } from "../../language/users";
import UsersTable from "../../components/Users/UsersTable";
import { getUsers } from "../../api/admin";
import Paginator from "../../components/uikit/Paginator";

import { connect } from "react-redux";
import { categoryEdit } from "../../api/category";
const countRowOnPage = 10;

class Operators extends React.Component {
  state = {
    users: [],
    pageCount: 0,
    currentPage: 1,
  };

  componentDidMount() {
    this.setUsers(5);
    document.title = "Список пользователей";
  }

  setUsers = (userTypeId) => {
    getUsers({ userTypeId }, this.fetchUsersSuccess);
  };

  fetchUsersSuccess = ({ results = [] }) => {
    let pageCount = 0;

    results = results.filter((user) => user.userTypeId == 5);
    if (results !== false && results) {
      pageCount = Math.ceil(results.length / countRowOnPage);
    }

    this.setState({ pageCount, users: results });
  };

  changePage = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { currentPage, pageCount, users } = this.state;
    const curPage = currentPage - 1;

    const slicedUsers = users.slice(
      curPage * countRowOnPage,
      curPage * countRowOnPage + countRowOnPage
    );
    return (
      <div className="Users">
        <h1 className="m-title without-border">Операторы</h1>
        <UsersTable users={slicedUsers} currentPage={currentPage}></UsersTable>
        <Paginator
          pageCount={pageCount}
          currentPage={currentPage}
          changePage={this.changePage}
        ></Paginator>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.filter((user) => user.userTypeId == 5),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (data) => dispatch({ type: "SET_USERS", payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Operators);
