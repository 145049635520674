import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { categoryList } from "../../api/category";
import { editUser, deleteUser } from "../../api/admin";

const expertTypes = [
  { value: true, title: "Проверенный" },
  { value: false, title: "Не проверенный" },
];

export default class User extends React.Component {
  state = {
    selectedOption: "",
    categories: [],
    user: {
      email: "",
      firstName: "",
      userTypeId: 1,
    },
  };

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = () => categoryList(this.fetchCategoriesSuccess);

  fetchCategoriesSuccess = ({ results = [] }) => {
    let categoriesToSelect = this.formatCategoriesToSelect(results);

    const userInfo = this.props.location.state.user;
    const userCategories = userInfo.categories;

    this.setState({
      user: userInfo,
      categories: categoriesToSelect,
      selectedOption: this.setUserCategories(
        userCategories,
        categoriesToSelect
      ),
    });
  };

  setUserCategories = (categoryNames, options) => {
    const userInfo = this.props.location.state.user;

    let userCategories = "";

    const categoriesArray = (categoryNames + "").split(",");

    const filteredCategories = options.filter((category) => {
      let flag = false;

      const checkEqualCategoryPredicate = (categoryName) => {
        const isEqualCategory = categoryName == category.label;

        if (isEqualCategory) {
          flag = true;
          userCategories += this.addUserCategory(category.value);
        }
      };

      if (categoryNames) categoriesArray.forEach(checkEqualCategoryPredicate);

      userInfo.categories = this.removeCategoryFromString(userCategories);

      return flag;
    });

    this.setState({ user: userInfo });

    return filteredCategories;
  };

  removeCategoryFromString = (categories) =>
    categories.substring(0, categories.length - 1);

  formatCategoriesToSelect = (categories) =>
    categories.map((category) => {
      const { id, title } = category;
      return { value: id, label: title };
    });

  handleChangeSelect = (selectedOption) => {
    const { user } = this.state;

    user.categories = "";

    const lastSelectedOptionIndex = selectedOption.length - 1;

    selectedOption.forEach((option, index) => {
      const isLastElementCategory = index == lastSelectedOptionIndex;

      user.categories += this.addUserCategory(
        option.value,
        isLastElementCategory
      );
    });

    this.setState({ selectedOption, user });
  };

  addUserCategory = (value, isLastElementCategory = false) =>
    isLastElementCategory ? "" + value : "" + value + ",";

  handleChange(fieldName, event) {
    const { value } = event.target;
    const { user } = this.state;
    user[fieldName] = value;
    this.setState({ user });
  }

  handleSubmit = () => {
    const {
      userTypeId,
      firstName,
      isCertified,
      isDeleted,
      categories,
    } = this.state.user;

    const { id } = this.props.match.params;
    const { selectedOption } = this.state;

    const updatedCategories = selectedOption.map((opt) => opt.value).join(",");

    let editedUser = {
      id,
      // email,
      firstName,
      isCertified,
      isDeleted,
      userTypeId,
      categories: updatedCategories,
    };

    editUser(editedUser, () => {});
  };

  handleChangeRadio(fieldName, value) {
    const { user } = this.state;
    user[fieldName] = value;
    this.setState({ user });
  }

  handleDeleteUser = (e) => {
    const { firstName = "" } = this.state.user;

    e.preventDefault();
    let isDelete = window.confirm(
      "Вы действительно хотите удалить пользователя " + firstName + "?"
    );

    if (isDelete) this.deleteUserFromDB();
  };

  deleteUserFromDB = () => {
    const { id } = this.props.match.params;
    deleteUser({ id }, this.deleteUserSuccess);
  };

  deleteUserSuccess = ({ result }) => {
    const isSuccessDelete = result === true;
    const { userTypeId = null } = this.state.user;
    const pathToRedirect = userTypeId === 1 ? "/users" : "/experts";

    if (isSuccessDelete) {
      this.showDeleteSuccessMessage();
      document.location.replace(pathToRedirect);
    }
  };

  showDeleteSuccessMessage = () => alert("Пользователь успешно удален!");

  showNotFoundCategoryMessage = ({ inputValue }) => {
    let msg = "Категория " + inputValue + " не найдена";
    return msg;
  };

  render() {
    const { categories, selectedOption } = this.state;

    const {
      email = "",
      firstName = "",
      userTypeId = null,
      isCertified,
    } = this.state.user;

    const isUser = userTypeId === 1;
    const isExpert = userTypeId === 2;
    const isAdmin = userTypeId === 4;

    const isHideCategoriesSelect = isUser || isAdmin;

    const pathToRedirect = userTypeId === 1 ? "/users" : "/experts";
    return (
      <div className="User mobile-padding">
        <h1 className="m-title">{email}</h1>
        <input
          value={firstName}
          className="mobile-w-100 margin-v-15"
          type="text"
          placeholder="Имя пользователя"
          onChange={(event) => this.handleChange("firstName", event)}
        />

        {isHideCategoriesSelect ? null : (
          <Select
            name="categories"
            value={selectedOption}
            noOptionsMessage={this.showNotFoundCategoryMessage}
            placeholder="Выберите категорию"
            onChange={this.handleChangeSelect}
            className="categories mobile-w-100 margin-v-15"
            options={categories}
            isMulti={true}
          />
        )}

        {isExpert
          ? expertTypes.map(({ value, title }, index) => {
              const isCertifiedExpert = isCertified === value;
              console.log(isCertified, value);
              return (
                <label key={index} for={"opt" + index} class="radio">
                  <input
                    type="radio"
                    name="fruit"
                    id={"opt" + index}
                    value={value}
                    class="hidden"
                    checked={isCertifiedExpert}
                    onClick={() => this.handleChangeRadio("isCertified", value)}
                  />
                  <span class="label"></span>
                  {title}
                </label>
              );
            })
          : null}
        <button
          className={"mobile-w-100 btn margin-v-15"}
          onClick={this.handleSubmit}
        >
          <Link to={pathToRedirect}>Изменить</Link>
        </button>
        <button className={"mobile-w-100 btn margin-v-15 border-red hover-red"}>
          <Link onClick={this.handleDeleteUser} to={pathToRedirect}>
            Удалить
          </Link>
        </button>
      </div>
    );
  }
}
