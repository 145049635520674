import $ from "jquery";
import { store } from "../App";
// Добавление категории {title:"Название категории"}
export async function categoryAdd(searchParams, success) {
  var url = "https://consult.ab-dpo.ru/api/category/add/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(searchParams),
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: { withCredentials: true },

    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

// Изменение категории {id:1,title:"Новое название"}
export async function categoryEdit(searchParams, success) {
  var url = "https://consult.ab-dpo.ru/api/category/edit/";
  $.ajax({
    url: url,
    type: "post",
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: { withCredentials: true },

    data: getParams(searchParams),
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

// Список категорий
export function categoryList(success, params = {}) {
  var url = "https://consult.ab-dpo.ru/api/category/list/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(params),
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: { withCredentials: true },

    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

function getParams(params) {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}
