import React, { useState } from "react";

import { Link } from "react-router-dom";
import { logout } from "../../api/common";
import { gray, text } from "../../constants/Constants";

const HeaderBar = ({ login = localStorage.getItem("login") }) => {
  const { pathname } = document.location;

  return (
    <div
      className="header-bar"
      ref={(el) => {
        if (document.location.pathname === "/login") {
          if (typeof el !== "undefined" && el !== null) {
            el.style.setProperty("padding-left", "10px", "important");
            el.style.setProperty("padding-right", "10px", "important");
          }
        }
      }}
    >
      <div className="row  justify-content-between">
        <div
          className="title d-none d-sm-flex"
          ref={(el) => {
            if (
              document.location.pathname === "/login" &&
              typeof el !== "undefined" &&
              el !== null
            ) {
              el.style.setProperty("margin-left", "10px", "important");
            }
          }}
        >
          Онлайн консультирование
        </div>
        {/* <a href="https://ab-dpo.ru/">Академия безопасности</a> */}
        <div className="right-header-block">
          {/* <Link to="/" className="help-button">
            Помощь
          </Link> */}
          {pathname == "/login" || pathname == "login" ? null : (
            <>
              <div style={{ fontSize: 16, color: text }}>{login}</div>
              <Link
                to="/login"
                className="exit-button"
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                Выход
              </Link>
            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
