import React, { useState } from 'react'
import { dialogGetQuotedMessage } from '../../../api/dialog'
import { gray, text, orange, lightblue } from '../../../constants/Constants'
import ReadMoreReact from 'read-more-react'
const Avatar = ({ type = 1, name = '' }) => {
  return (
    <div className='avatar'>
      {type === 1 ? (
        <div className={'icon question'}>?</div>
      ) : type === 3 ? (
        <div className='circle' style={{ backgroundColor: text }}>
          <div
            style={{ backgroundColor: '#fff', height: 30 }}
            className={'icon tie'}
          ></div>
        </div>
      ) : (
        <div className='circle'>
          <div className={'icon hat'}></div>
        </div>
      )}
      {/* <div className="userName">{name}</div> */}
    </div>
  )
}

const AcceptBlock = ({ accept }) => {
  return (
    <div className='AcceptBlock' onClick={accept}>
      <div className='check'></div>
      <p>Акцептировать ответ</p>
    </div>
  )
}

const QuotedMessage = ({ quotedMessage }) => {
  var messageId = null
  let { text = '' } = quotedMessage
  var re = '&quot;'
  text = text.split(re).join('"')

  return (
    <div className='QMessage'>
      <span className='qoute'>Цитата: </span>
      {text}
      {messageId !== null ? (
        <QuotedMessage quotedMessageId={messageId}></QuotedMessage>
      ) : null}
    </div>
  )
}
const Text = ({
  text,
  quotedMessage,
  isAnswer = true,
  isAccepted,
  fromUserType,
  accept,
  children
}) => {
  text = text.split('⏎').join('\r')
  var re = '&quot;'
  text = text.split(re).join('"')

  return (
    <div className='text'>
      <div
        style={{
          borderRightColor:
            fromUserType === 1
              ? lightblue
              : fromUserType === 2
              ? orange
              : '#1f1f1f'
        }}
        className='message'
      >
        {children}

        {typeof text != 'undefined' && text ? (
          <ReadMoreReact
            min={80}
            ideal={200}
            max={500}
            readMoreText='Читать далее...'
            text={text}
          ></ReadMoreReact>
        ) : null}
      </div>
      {isAnswer == true ? (
        <div className='isAnswer'>
          <div className='box'>
            <div className='check'></div>
          </div>
          Эксперт отметил данное сообщение, как окончательный ответ
        </div>
      ) : null}
      {/* {quotedMessage ? (
        <QuotedMessage quotedMessageId={quotedMessage}></Qu otedMessage>
      ) : null} */}
    </div>
  )
}

const Time = ({ time = '' }) => {
  time = time.split(' ')
  time[0] = time[0].split('-')
  time[0] = time[0][2] + '.' + time[0][1] + '.' + time[0][0]
  return (
    <div className='createTime'>
      <div className='timeRow'>
        <div className='time icon'></div>
        <div className='element'>{time[1]}</div>
      </div>
      <div className='timeRow'>
        <div className='calendar icon'></div>
        <div className='element'>{time[0]}</div>
      </div>
    </div>
  )
}

const EMessageBlock = ({ msg = {}, accept }) => {
  const {
    id,
    fromUserName,
    createTime,
    isAccepted,
    isAnswer,
    quotedMessage,
    text,
    fromUserType
  } = msg

  return (
    <div className='MessageBlock mobile-padding'>
      <Text
        text={text}
        isAnswer={isAnswer}
        isAccepted={isAccepted}
        quotedMessage={quotedMessage}
        fromUserType={fromUserType}
        accept={accept}
      >
        <Avatar type={fromUserType} name={fromUserName}></Avatar>
      </Text>
      {quotedMessage ? (
        <QuotedMessage quotedMessage={quotedMessage}></QuotedMessage>
      ) : null}
      <Time time={createTime}></Time>
    </div>
  )
}

export default EMessageBlock
