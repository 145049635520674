import React, { useEffect, useState } from "react";
import { getUsers } from "../../api/admin";
import { Input } from "../../components/uikit/Input/Input";
import { ExpertStatisticRow } from "./ExpertStatisticRow/ExpertStatisticRow";

const ExpertStatistic = () => {
  const [experts, setExperts] = useState([]);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    loadExperts();
  }, []);

  const loadExperts = () => {
    getUsers({ userTypeId: 2, dateFrom, dateTo }, ({ results = [] }) =>
      setExperts(results)
    );
  };

  const handleTypeDateFrom = (text) => {
    setDateFrom(text);
    loadExperts();
  };
  const handleTypeDateTo = (text) => {
    setDateTo(text);
    loadExperts();
  };

  return (
    <div className="w-100">
      <h1 className="m-title mb-4 without-border">Статистика по экспертам</h1>
      <div>
        <div className="mb-3">
          <Input
            placeholder="От"
            type={"date"}
            value={dateFrom}
            onChange={handleTypeDateFrom}
          />
          <Input
            placeholder="От"
            type={"date"}
            value={dateTo}
            onChange={handleTypeDateTo}
          />
        </div>
        {experts.map((expert) => (
          <ExpertStatisticRow expert={expert} />
        ))}
      </div>
    </div>
  );
};

export default ExpertStatistic;
