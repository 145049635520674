import React from "react";
import { getFormattedDialogs, dialogList } from "../../api/dialog";
import Table from "../../components/uikit/DialogTable/Table";
import { categoryEdit } from "../../api/category";
import EmptyMessage from "../../components/uikit/EmptyMessage";
import { connect } from "react-redux";
import FiltersBlock from "../../components/uikit/FiltersBlock";
import { getMessagesTimer } from "../../constants/Constants";
import { Input } from "../../components/uikit/Input/Input";
import Button from "../../components/uikit/Button";

class Questions extends React.Component {
  state = {
    questions: [],
    filteredQuestions: [],
    searchString: "",
    isLoading: false,
  };

  startLoading = () => this.setState({ isLoading: true });
  offLoading = () => this.setState({ isLoading: false });

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.filters !== this.props.filters) {
      this.loadDialogs();
    }
  }
  loadDialogs = () => {
    const { searchString } = this.state;
    const newFilter = this.props.filters;

    newFilter.available = true;
    newFilter.isController = false;
    newFilter.someoneElse = true;
    newFilter.searchQuery = searchString;

    this.startLoading();
    // newFilter.orderByNew = false;

    getFormattedDialogs(newFilter, (data) => {
      if (data && data !== false) {
        data.map((question, index) => {
          question.status = null;
        });
        this.offLoading();
        this.setState({ questions: data });
      }
    });
  };
  componentDidMount() {
    this.loadDialogs();
    document.title = "Вопросы пользователей";
    setInterval(() => this.loadDialogs(), getMessagesTimer);
  }

  searchQuestions = (text) => {
    this.loadDialogs(text);
  };

  handleChangeSearchQuery = (text) => this.setState({ searchString: text });
  render() {
    const { questions, searchString, isLoading } = this.state;
    // console.log(this.state.questions.category.title);
    return (
      <div className="Questions">
        <h1 className="m-title">Вопросы пользователей</h1>

        <FiltersBlock questions={true}></FiltersBlock>

        <Input
          placeholder="Поиск"
          value={searchString}
          onBlur={this.searchQuestions}
          onChange={this.handleChangeSearchQuery}
        />

        {questions.length === 0 ? (
          <EmptyMessage message={"Вопросов не найдено"} />
        ) : (
          <Table
            to="/question/"
            // category={}
            dataLength={questions.length}
            headers={[
              { title: "", tip: null, colSpan: 1 },
              { title: "Вопрос", tip: null, colSpan: 1 },
              { title: "Категория", tip: null, colSpan: 1 },
              { title: "", tip: null, colSpan: 1 },
            ]}
            data={questions}
          ></Table>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Questions);
