import $ from "jquery";
import { red, green, yellow, darkblue } from "../constants/Constants";
import { categoryList } from "./category";
import { store } from "../App";
var url = "https://consult.ab-dpo.ru/api";

// Основное тело запросов
const ajaxBody = (methodUrl, data, success = () => {}) => {
  return {
    url: url + methodUrl,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: { withCredentials: true },

    async: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    // async: false,
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  };
};

function getParams(obj) {
  // for (var propName in obj) {
  //   if (obj[propName] === null || obj[propName] === undefined) {
  //     delete obj[propName]
  //   }
  // }
  // return obj
  return Object.keys(obj)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    })
    .join("&");
}

// // Список диалогов
export async function dialogList(
  data = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: null,
    isController: null,
    someoneElse: null,
    dialogCount: null,
  },
  success
) {
  var methodUrl;
  if (data.isController === true) methodUrl = "/dialog/controllerList/";
  else methodUrl = "/dialog/list/";
  if (data.someoneElse == null) delete data.someoneElse;

  $.ajax(ajaxBody(methodUrl, data, success));
}

// Получение сообщений диалога
export async function dialogGet(data = { dialogId: null }, success) {
  var methodUrl = "/dialog/get/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Получение конкретного сообщения
export async function dialogGetQuotedMessage(
  data = { messageId: null },
  success
) {
  var methodUrl = "/dialog/getQuotedMessage/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Отправка сообщения
export async function dialogSendMessage(
  data = {
    dialogId: null,
    categoryId: null,
    text: null,
    isAnswer: null,
    quotedMessageId: null,
    quotedDialogId: null,
  },
  success
) {
  var methodUrl = "/dialog/sendMessage/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Акцептирование сообщения
export async function dialogAcceptMessage(
  data = {
    messageId: null,
    isAccepted: true,
  },
  success
) {
  var methodUrl = "/dialog/acceptMessage/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Закрытие диалога
export async function dialogClose(
  data = {
    dialogId: null,
  },
  success
) {
  var methodUrl = "/dialog/close/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Оценка диалога
export async function dialogRate(
  data = {
    dialogId: null,
    userRating: null,
    userRatingComment: null,
    controllerRating: null,
  },
  success
) {
  var methodUrl = "/dialog/rate/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Изменение категории
export async function dialogEdit(
  data = {
    dialogId: null,
    categoryId: 2,
  },
  success
) {
  var methodUrl = "/dialog/edit/";
  $.ajax(ajaxBody(methodUrl, data, success));
}
// Взять вопрос
export async function takeQuestion(
  data = {
    dialogId: null,
  },
  success
) {
  var methodUrl = "/dialog/takeQuestion/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

export async function getFormattedDialogs(
  {
    // userId = 1,
    available = false,
    count = 100,
    offset = 0,
    isClosed = null,
    userRating = null,
    dialogId = null,
    categoryId = null,
    orderByNew = null,
    isController = null,
    someoneElse = null,
    searchQuery = "",
  },
  success
) {
  await categoryList(({ results }) => {
    var dialogs = [];
    var categories = [];
    categories = results;
    dialogList(
      someoneElse !== null
        ? {
            available,
            count,
            offset,
            isClosed,
            userRating,
            dialogId,
            categoryId,
            orderByNew,
            isController,
            searchQuery,
            someoneElse: true,
          }
        : {
            available,
            count,
            offset,
            isClosed,
            userRating,
            dialogId,
            categoryId,
            orderByNew,
            searchQuery,
            isController,
          },
      ({ results, result = true }) => {
        if (result === true && results !== false) {
          dialogs = results;
        }
        if (dialogs !== false || typeof dialogs !== undefined) {
          for (var i = 0; i < dialogs.length; i++) {
            var dialog = dialogs[i];

            dialog.category = categories.find((category) => {
              return category.id === dialog.categoryId;
            });

            const { dialogCreateTime } = dialog;

            if (typeof lastUserMessageTime !== "object") {
              var time = dialogCreateTime.split(" ");

              dialog.lastUserMessageTime = {
                date: time[0],
                time: time[1],
              };
            }

            const {
              isClosed,
              isAccepted,
              userRating,
              havingAnswerFromExpert,
              gotExpert,
            } = dialog;

            const good = {
              status: "good",
              color: green,
              text: "Диалог закрыт",
            };
            const waiting = {
              status: "waiting",
              color: yellow,
              text: "Ожидание",
            };
            const inwork = {
              status: "waiting",
              color: darkblue,
              text: "Взят в работу",
            };
            const conversation = {
              status: "good",
              color: darkblue,
              text: "Ответ готов",
            };

            // Если диалог закрыт то все хорошо
            if (isClosed === true) {
              dialog["status"] = good;
              continue;
            }

            // Если диалог не взят экспертом - ожидание
            if (gotExpert == false) {
              dialog["status"] = waiting;
              continue; // Следующий диалог
            } else {
              // Если есть ответ от эксперта - идет общение
              if (havingAnswerFromExpert == true) {
                dialog["status"] = conversation;
                continue; // Следующий диалог
              } else {
                dialog["status"] = inwork;
                continue; // Следующий диалог
              }
            }
          }
        }

        success(dialogs);
      }
    );
  });
}
