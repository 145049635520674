import React from "react";

export default class Experts extends React.Component {
  render() {
    return (
      <div className="Experts">
        <h1>Конкретный вопрос</h1>
      </div>
    );
  }
}
