import React from "react";

const EmptyMessage = ({ message = "" }) => {
  return (
    <div className="EmptyMessage mobile-padding w-100">
      <div className="empty"></div>
      <div className="message">{message}</div>
    </div>
  );
};

export default EmptyMessage;
