import $ from "jquery";
import { store } from "../App";
var url = "https://consult.ab-dpo.ru/api";

const ajaxBody = (methodUrl, data, success = () => {}) => {
  return {
    url: url + methodUrl,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    xhrFields: { withCredentials: true },

    // beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      // store.dispatch({ type: "SET_SUCCESS" });
      success(res);
    },
    error: function (err) {
      // store.dispatch({ type: "SET_FAIL" });
    },
  };
};
function getParams(params) {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}

// Добавление чистового вопроса
export async function faqAdd(
  data = {
    question: null,
    answer: null,
    categoryId: null,
    rule: null,
    dialogId: null,
    norms: [],
    text: [],
  },
  success
) {
  var methodUrl = "/faq/add/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Актуализация контента

export async function faqActualize(
  data = {
    uuid: null,
    content_id: null,
  },
  success
) {
  var methodUrl = "/faq/actualize/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Изменение чистового вопроса
export async function faqEdit(
  data = {
    id: null,
    question: null,
    answer: null,
    categoryId: null,
    rule: null,
    dialogId: null,
  },
  success
) {
  var methodUrl = "/faq/edit/";
  console.log(methodUrl, data);

  $.ajax(ajaxBody(methodUrl, data, success));
}

// Список чистовых вопросов
export async function faqList(
  data = {
    categoryId: null,
  },
  success
) {
  var methodUrl = "/faq/list/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Получение чистового вопроса
export async function faqGet(
  data = {
    id: 1,
  },
  success
) {
  var methodUrl = "/faq/get/";
  $.ajax(ajaxBody(methodUrl, data, success));
}

// Получение чистового вопроса
export async function faqDelete(
  data = {
    id: 1,
  },
  success
) {
  var methodUrl = "/faq/delete/";
  $.ajax(ajaxBody(methodUrl, data, success));
}
