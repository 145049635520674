import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const FormForQuotedMessage = ({
  quotedMessage,
  to,
  isShow = true,
  onSend = () => {}
}) => {
  const [text, changeText] = useState('')
  if (isShow === true)
    return (
      <div className='FormForQuotedMessage mobile-padding'>
        <p className='qMsg '>
          <span className='gray'>Цитата: </span>
          {quotedMessage.text}
        </p>
        <textarea
          value={text}
          className=''
          style={{ width: '-webkit-fill-available' }}
          onChange={e => changeText(e.target.value)}
        />
        <Link
          to={to}
          className=' mobile-w-100'
          onClick={e => {
            console.log(to)

            onSend(text)
          }}
        >
          <div className='letter'></div>
          Отправить сообщение
        </Link>
      </div>
    )
  else {
    return
  }
}

export default FormForQuotedMessage
