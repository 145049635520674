export const searchInput = "Поиск по сообщениям";
export const closeDialog = "Завершить диалог";
export const sendMessage = "Ответить";
export const text = {
  commonText: "Диалог завершен",
  good: "Спасибо за положительный отзыв!",
  bad: "Спасибо за ваш отзыв! Мы примем меры для решения вашего вопроса",
};
export const createFAQButton = "Создать чистовой вопрос";

export const closeDialogSuccess = "Диалог успешно закрыт!";
