import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

const Preloader = ({ status = '', clearStatus }) => {
  useEffect(() => {
    // Если новый статус лоадер, то убрать его только через пару секунд после завершения загрузки

    const timer = setTimeout(() => {
      if (status == 'check' || status == 'close-preloader') {
        clearStatus()
      }
    }, 2000)
    return () => clearTimeout(timer)
  })
  return (
    <div className='preloader' style={{ right: status == '' ? -100 : 20 }}>
      <div className='icon'>
        <div className={status}></div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({ status: state.preloader })
const mapDispatchToProps = dispatch => {
  return {
    clearStatus: () =>
      dispatch({
        type: 'CLEAR_STATUS'
      })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Preloader)
