import React from "react";
import ReadMoreReact from "read-more-react";
import { lightblue, orange } from "../../../constants/Constants";

const Avatar = ({ type = 1, name = "" }) => {
  console.log("t", type);

  return (
    <div className="avatar">
      {type === 1 ? (
        <div className={"icon question"}>?</div>
      ) : type === 3 ? (
        <div className="circle" style={{ backgroundColor: "#333" }}>
          <div
            style={{ backgroundColor: "#fff", height: 30 }}
            className={"icon tie"}
          ></div>
        </div>
      ) : (
        <div className="circle">
          <div className={"icon hat"}></div>
        </div>
      )}
      {type == 2 ? <div className="userName">{name}</div> : null}
    </div>
  );
};

const AcceptBlock = ({ accept }) => {
  return (
    <div className="AcceptBlock" onClick={accept}>
      <div className="check"></div>
      <p>Акцептировать ответ</p>
    </div>
  );
};
const ChainBlock = ({ chain }) => {
  return (
    <div className="ChainToDialog" onClick={chain}>
      <div className="chain"></div>
      <p>Прикрепить к диалогу с экспертом</p>
    </div>
  );
};

const Text = ({
  text,
  quotedMessage,
  isAnswer = true,
  isAccepted,
  fromUserType,
  accept,
  chain,
  children,
}) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="text">
      <div
        style={{
          borderRightColor:
            fromUserType === 1
              ? lightblue
              : fromUserType === 2
              ? orange
              : "#1f1f1f",
        }}
        className="message"
      >
        {children[0]}
        {typeof text != "undefined" && text ? (
          <ReadMoreReact
            min={80}
            ideal={200}
            max={500}
            readMoreText="Читать далее..."
            text={text}
          ></ReadMoreReact>
        ) : null}
      </div>
      {/* {quotedMessage === null ? null : (
        <p className="quotedMessage">{quotedMessage}</p>
      )} */}
      {isAnswer == true ? (
        <div className="isAnswer">
          <div className="box">
            <div className="check"></div>
          </div>
          Эксперт отметил данное сообщение, как окончательный ответ
        </div>
      ) : null}
      {isAccepted === false && fromUserType === 2 ? (
        <AcceptBlock accept={accept}></AcceptBlock>
      ) : null}
      <ChainBlock chain={chain}></ChainBlock>
      {children[1]}
    </div>
  );
};

const Time = ({ time = "" }) => {
  time = time.split(" ");
  time[0] = time[0].split("-");
  time[0] = time[0][2] + "." + time[0][1] + "." + time[0][0];
  return (
    <div className="createTime">
      <div className="timeRow">
        <div className="time icon"></div>
        <div className="element">{time[1]}</div>
      </div>
      <div className="timeRow">
        <div className="calendar icon"></div>
        <div className="element">{time[0]}</div>
      </div>
    </div>
  );
};

const CMessageBlock = ({ msg = {}, accept, chain }) => {
  const {
    id,
    fromUserName,
    createTime,
    isAccepted,
    isAnswer,
    quotedMessage,
    text,
    fromUserType,
  } = msg;
  console.log(fromUserType);

  return (
    <div className="MessageBlock mobile-padding">
      <Text
        text={text}
        isAnswer={isAnswer}
        isAccepted={isAccepted}
        quotedMessage={quotedMessage}
        fromUserType={fromUserType}
        accept={accept}
        chain={chain}
      >
        <Avatar type={fromUserType} name={fromUserName}></Avatar>
        <Time time={createTime}></Time>
      </Text>
    </div>
  );
};

export default CMessageBlock;
