import React from "react";
import Button from "./Button";

import { withRouter } from "react-router-dom";

const LeftButtonBlockWithHide = props => {
  const { location, navLinks = [] } = props;
  if (location.pathname.match(/login/)) {
    return null;
  }
  return (
    <div className="logo-width" style={{ maxWidth: 230 }}>
      <div className="menu-container max-width">
        {navLinks.map((link, index) => {
          return <Button key={index} link={link}></Button>;
        })}
      </div>
    </div>
  );
};
const LeftButtonBlock = withRouter(LeftButtonBlockWithHide);

export default LeftButtonBlock;
