import React from "react";
const DialogHeader = ({ title = "", category = "" }) => {
  return (
    <div className="DialogHeader">
      <h1 className="title h3 m-title">{title}</h1>
      <p className="category mobile-padding">{category.split("_").join(" ")}</p>
    </div>
  );
};

export default DialogHeader;
