import React from 'react'
import MessageBlock from '../../components/uikit/MessageBlock/MessageBlock'
import {
  dialogSendMessage,
  dialogGet,
  // getFormattedDialogs,
  dialogList,
  dialogClose
} from '../../api/dialog'
import { sendMessage, createFAQButton } from '../../language/Dialogs'

import { Link } from 'react-router-dom'
import CMessageBlock from '../../components/uikit/MessageBlock/CMessageBlock'
import CEMessageBlock from '../../components/uikit/MessageBlock/CEMessageBlock'
import FaqElement from '../../components/uikit/FaqElement'
import UserRating from '../../components/uikit/UserRating'
import TextAreaBlock from '../../components/uikit/MessageBlock/TextAreaBlock'
import Messages from '../../components/uikit/MessageBlock/Messages'
import { getMessagesTimer } from '../../constants/Constants'

const ButtonBlock = ({ onSend, id, closeDialogF }) => {
  return (
    <div className='btn-block mobile-margin'>
      <button onClick={onSend} className='dialog-btn btn-send' id={id}>
        <div className='letter'></div>
        {sendMessage}
      </button>
      <button
        onClick={closeDialogF}
        className='dialog-btn btn-dialog-close mobile-w-100'
      >
        {'Закрыть диалог'}
      </button>
    </div>
  )
}

export default class CDialog extends React.Component {
  state = {
    id: null,
    msgs: [{ fromUserType: 0 }],
    response: '',
    isClose: false,
    status: null,
    dialogId: null,
    category: '',
    userRating: null,
    userRatingComment: '',
    faq: []
  }
  componentDidMount () {
    const { id, category } = this.props.match.params
    this.setState({ id, category })
    this.loadMessages(id)
    this.getDialogInfo(id)
    setInterval(() => this.loadMessages(id), getMessagesTimer)

    document.title = 'Мои диалоги'
  }

  getDialogInfo = id => {
    let dialog
    dialogList({ dialogId: id }, ({ results }) => {
      dialog = results[0]
    })
    if (typeof dialog !== 'undefined') {
      const {
        isClosed,
        status = null,
        dialogId = null,
        faq = [],
        userRating = 0,
        userRatingComment = ''
      } = dialog

      this.setState({
        isClose: isClosed,
        dialogId,
        faq,
        userRating,
        userRatingComment
      })
    }
  }

  loadMessages = id => {
    dialogGet({ dialogId: id }, ({ results = [] }) => {
      console.log('Сообщения', results)
      this.setState({ msgs: results })
    })
  }
  onSend = e => {
    const { id, response } = this.state
    const isAnswer = this.state.msgs.length == 1 ? true : false
    dialogSendMessage(
      {
        dialogId: id,
        text: response,
        isAnswer: isAnswer
      },
      data => {
        this.loadMessages(id)
        console.log(data)
      }
    )
    this.MessagesRef.current.scroll()
    this.setState({ response: '' })
  }
  closeDialogF = success => {
    const { id } = this.state
    dialogClose({ dialogId: id }, success)
    this.getDialogInfo(id)
  }

  handleChange = e => {
    let { value } = e.target
    const { response } = this.state
    let newStrLength = response.length + value.length
    if (newStrLength > 10000) {
      value = value.substr(0, 10000)
    }
    this.setState({ response: value })
  }

  MessagesRef = React.createRef()
  render () {
    const {
      msgs,
      status,
      dialogId,
      faq = [],
      response,
      userRating,
      userRatingComment = '',
      isClose
    } = this.state
    const { id, category } = this.props.match.params
    return (
      <div className='Dialog'>
        {dialogId !== null ? (
          <Link
            className='DialogTitle h1 darkblue m-title without-border'
            to={{
              pathname: '/check/' + category + '/' + dialogId,
              state: { qDialogId: id }
            }}
          >
            <div className='caret-down'></div>
            <div>Перейти к обсуждаемому диалогу</div>
          </Link>
        ) : (
          <Link
            className='DialogTitle h1 darkblue m-title without-border'
            to={{
              pathname: '/dialog/'
            }}
          >
            <div className='caret-down'></div>
            <div>Мои диалоги</div>
          </Link>
        )}

        <Messages ref={this.MessagesRef}>
          {msgs.map((msg, index) => {
            return <CEMessageBlock key={index} msg={msg}></CEMessageBlock>
          })}
        </Messages>
        {this.state.isClose === true ? (
          <>
            {/* {msgs[0].fromUserType === 1 &&
            userRating !== null &&
            userRatingComment ? (
              <div className="userRatingBlock">
                <UserRating rating={userRating} comment={"ASda;l"}></UserRating>
              </div>
            ) : null} */}

            <div className='dialogClosed'>
              <div className='check'></div>
              <p>Диалог закрыт</p>
            </div>
          </>
        ) : (
          <TextAreaBlock
            onSend={this.onSend}
            handleChange={this.handleChange}
            value={response}
            closeDialog={this.closeDialogF}
            id={id}
          ></TextAreaBlock>
        )}
      </div>
    )
  }
}
