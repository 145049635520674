import React from "react";
import { Link } from "react-router-dom";

import Timer from "react-compound-timer";

import {
  formatTime,
  reverseSting,
} from "../../../commonFuction/commonFunction";

import {
  msInHour,
  hoursForAnswer,
  isValid,
} from "../../../constants/Constants";

function checkImportantQuestion(lastTime) {
  let now = new Date();
  let time = getTimeInMilliseconds(lastTime);
  let hours = (now - time) / msInHour;
  if (hours >= hoursForAnswer) return true;
  else return false;
}
function getTimeForTimer(time) {
  time = getTimeInMilliseconds(time);
  let now = new Date();
  let hour = msInHour;
  let delta = now - time;
  time = hoursForAnswer * hour - delta;

  if (time > 0) return time;
  else return -1;
}
function getTimeInMilliseconds(datetime) {
  // const { date, time } = lastUserMessageTime;
  let msgTime = new Date(datetime);
  return msgTime;
}

const TimeRow = ({ lastUserMessageTime }) => {
  return (
    <>
      <div className="timeRow">
        <div className="time"></div>
        <div className="element">{lastUserMessageTime.time}</div>
      </div>
      <div className="timeRow">
        <div className="calendar"></div>
        <div className="element">
          {reverseSting(lastUserMessageTime.date.split("-").join("."), ".")}
        </div>
      </div>
    </>
  );
};

const Warning = () => {
  return <div style={{ fontSize: "10pt" }}>Необходимо ответить!</div>;
};

const TimerRow = ({ timer }) => {
  return (
    <div className="timeRow">
      <div className="time"></div>
      <Timer
        initialTime={timer}
        formatValue={(value) => (value < 10 ? "0" + value : value)}
        direction="backward"
      >
        {() => (
          <div className="timer">
            <Timer.Hours />:
            <Timer.Minutes />:
            <Timer.Seconds />
          </div>
        )}
      </Timer>
    </div>
  );
};

export const Row = ({
  index,
  status,
  text,
  category,
  lastUserMessageTime,
  lastUserMessageType = 1,
  dialogCreateTime,
  to,
  havingAnswerFromExpert,
  isBetweenControllerAndExpert,
  isClosed,
  question,
  isRead = true,
  isMobile = false,
  overdue,
}) => {
  var isImportant = false;
  let timer;
  const userType = localStorage.getItem("userType");
  if (userType != 1 && isClosed == false) {
    isImportant = overdue;

    // Если еще не проссрочили, но и ответа от эксперта нет - создаем таймер
    if (overdue == false && havingAnswerFromExpert == false) {
      timer = getTimeForTimer(dialogCreateTime);
    }
  }

  var re = "&quot;";
  if (typeof text !== "undefined") text = text.split(re).join('"');
  if (typeof question !== "undefined") question = question.split(re).join('"');

  return (
    <div
      className="summary"
      style={{
        backgroundColor: isRead === true ? "#fff" : "#fafdff",
        paddingRight: isMobile === false ? null : 10,
        paddingLeft: isMobile === true ? 10 : status === null ? 0 : null,
      }}
    >
      <div className="topRow">
        {status === null ? null : (
          <div className="leftBlock">
            <div
              className={"status " + status.status}
              style={{ backgroundColor: status.color }}
            ></div>
            <div className="status-text">{status.text}</div>
          </div>
        )}
        <div
          className="qBlock"
          style={{
            paddingLeft: status === null ? 0 : null,
          }}
        >
          {question ? (
            <div className="text">
              <Link to={to}>{question}</Link>
            </div>
          ) : text ? (
            <>
              {isImportant == true ? (
                <div className="text">
                  <Link to={to}>
                    <span className="important">!</span>
                    <span className=" withUnderline">{text}</span>
                  </Link>
                </div>
              ) : (
                <div className="text">
                  <Link to={to}>{text}</Link>
                </div>
              )}
              <div className="bottomRow">
                <div
                  className="categoryTitle"
                  // to={to + "/" + category.title.split(" ").join("_")}
                >
                  {isValid(category) ? category.title : ""}
                </div>
                <div className="timeBlock">
                  {
                    // Если эксперт еще не дал ответ и пользователь не обычный юзер и диалог не закрыт
                    isClosed == false &&
                    localStorage.getItem("userType") != 1 ? (
                      // Если между контроллером и экспертом то точно время показываем последнего сообщения
                      isBetweenControllerAndExpert ? (
                        <TimeRow
                          lastUserMessageTime={lastUserMessageTime}
                        ></TimeRow>
                      ) : // Если диалог просрочен — то ТОЧНО выдаем предупреждение
                      overdue == true ? (
                        <Warning></Warning>
                      ) : havingAnswerFromExpert == false ? (
                        // Если диалог не просрочен, но ответа от эксперта еще нет - таймер
                        <TimerRow timer={timer}></TimerRow>
                      ) : (
                        <TimeRow
                          lastUserMessageTime={lastUserMessageTime}
                        ></TimeRow>
                      )
                    ) : (
                      // Если юзер или диалог закрыт - просто время
                      <TimeRow
                        lastUserMessageTime={lastUserMessageTime}
                      ></TimeRow>
                    )
                  }
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
