import React from "react";
import { connect } from "react-redux";
import { manualText } from "../../language/Texts";
import HelpTip from "../../components/uikit/helpScreen/HelpTip";

class Help extends React.Component {
  componentDidMount() {
    document.title = "Руководство";
  }
  render() {
    const accountType = 0;
    return (
      <div className="Help">
        <div className="h1">
          <h1 className="m-title">Руководство</h1>
          {/* <h3>что-то езе</h3> */}
        </div>
        <div className="accordion" id="accordion">
          {accountType
            ? manualText[accountType].map((tip, index) => {
                const { title, text } = tip;
                return (
                  <HelpTip
                    key={index}
                    index={index}
                    title={title}
                    text={text}
                  ></HelpTip>
                );
              })
            : manualText["user"].map((tip, index) => {
                const { title, text } = tip;
                return (
                  <HelpTip
                    key={index}
                    index={index}
                    title={title}
                    text={text}
                  ></HelpTip>
                );
              })}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    accountType: state.account_type
  }),
  dispatch => ({})
)(Help);
