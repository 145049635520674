import React from "react";
import { closeDialog, sendMessage } from "../../../language/Dialogs";

import useModal from "use-react-modal";
import ModalDialog from "../ModalDialog";
import ClosedDialogBlock from "../ClosedDialogBlock";
import { red, darkblue } from "../../../constants/Constants";

const ButtonBlock = ({ onSend, id, closeDialogF, isEmpty }) => {
  let userType = localStorage.getItem("userType");
  const classNameButton = "dialog-btn btn-send mobile-w-100";

  return (
    <div className="btn-block">
      <button
        onClick={onSend}
        className={
          isEmpty === true
            ? classNameButton + " disabled"
            : classNameButton + " filled"
        }
        id={id}
      >
        <div className="letter"></div>
        {sendMessage}
      </button>
    </div>
  );
};

const TextAreaBlock = ({
  showButtons = false,
  handleChange = () => {
    console.log("Forgot Textarea handleChange");
  },
  value = "",
  onSend = () => {
    console.log("Forgot Textarea onSend");
  },
  id = "",
  closeDialog = () => {},
  maxStrLength = 10000,
}) => {
  return (
    <div className="TextAreaBlock mobile-padding">
      <textarea
        spellCheck="false"
        className="QArea"
        placeholder={"Введите ваш ответ"}
        onChange={handleChange}
        value={value}
      ></textarea>
      <div
        className="counter"
        style={{ color: value.length === maxStrLength ? red : darkblue }}
      >
        {value.length}/{maxStrLength}
      </div>
      <ButtonBlock
        isEmpty={value == ""}
        onSend={onSend}
        closeDialogF={closeDialog}
        id={id}
      ></ButtonBlock>
    </div>
  );
};

export default TextAreaBlock;
