import React from "react";

import { login } from "../../api/common";
import { Link } from "react-router-dom";
import { darkblue, green } from "../../constants/Constants";
import { editUser } from "../../api/admin";
import { connect } from "react-redux";

const UsersTypes = [
  "Пользователь",
  "Эксперт",
  "Контроллер",
  "Администратор",
  "Оператор",
];

const UserRow = ({
  updateUser,
  id,
  email,
  firstName,
  nickname,
  userTypeId,
  isCertified = null,
  isDeleted,
  index,
  categories = [],
}) => {
  const user = {
    id,
    firstName,
    isCertified,
    isDeleted,
    userTypeId,
    categories,
    email,
  };
  // console.log("User form UserRow", user)
  return (
    <div key={id} className="UserRowMobile mobile-padding">
      <div>
        <div className="firstName">
          {firstName}{" "}
          <div style={{ marginLeft: "auto", display: "flex" }}>
            <Link
              to="/"
              onClick={async () => {
                await login({ email: email }, () => {
                  localStorage.setItem("login", email);
                });
              }}
              className="login"
            ></Link>{" "}
          </div>
          <div style={{ marginLeft: 10, display: "flex" }}>
            <Link
              to={{
                pathname: "/users/" + id,
                state: {
                  user,
                },
              }}
              className="edit"
            ></Link>
          </div>
        </div>
        <div className="email">{email}</div>
        <div className="userType">
          {userTypeId === 2 ? (
            <div
              className="hat"
              style={{ backgroundColor: isCertified ? darkblue : green }}
            ></div>
          ) : null}
          {userTypeId === 3 ? (
            <div className="tie" style={{ backgroundColor: "#333" }}></div>
          ) : null}
          {UsersTypes[userTypeId - 1]}
          {userTypeId === 2
            ? isCertified === true
              ? " проверенный"
              : " начинающий"
            : null}
          {/* <div style={{ marginLeft: "auto", display: "flex" }}>
            <div className="cancel"></div>
          </div> */}
        </div>
        {categories.length === 0 ? null : (
          <div className="categories">
            {categories.map((category, index) => {
              return (
                <div className="category" key={index}>
                  {category}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: () => dispatch({ type: "UPDATE_USERS" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserRow);
