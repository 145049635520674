import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { App } from "./App";

// Только только в dev режиме
const log = (...msgs) => {
  if (process.env.NODE_ENV === "development") console.log(...msgs);
};

global.log = log;
ReactDOM.render(<App userType={"1"} />, document.getElementById("user"));
ReactDOM.render(<App userType={"2"} />, document.getElementById("expert"));
ReactDOM.render(<App userType={"3"} />, document.getElementById("controller"));
ReactDOM.render(<App userType={"4"} />, document.getElementById("admin"));
ReactDOM.render(<App userType={"5"} />, document.getElementById("operator"));
serviceWorker.unregister();
