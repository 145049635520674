import React from "react";

import { Link } from "react-router-dom";
import { Star } from "./ModalDialog";
function Stars({ starsArr = [], changeFilledStars = () => {} }) {
  
  return (
    <div className="Stars">
      <div className="starsBlock">
        {starsArr.map((s, index) => {
          const { fill } = s;
          return (
            <Star
              onClick={() => changeFilledStars(index)}
              f={fill}
              key={index}
            ></Star>
          );
        })}
      </div>
    </div>
  );
}

function getStarsArr(count) {
  let arr = [];
  for (let i = 0; i < count; i++) {
    arr.push({ fill: true });
  }
  return arr;
}
const UserRating = (rating = 5, comment = "") => {
  console.log(comment, "коммент");
  const starsArr = getStarsArr(parseInt(rating));
  return (
    <div className="UserRating">
      <span>Оценка пользователя</span>
      <Stars starsArr={starsArr}></Stars>
      <Link to={"/dialog"}></Link>
      <p>Комментарий к оценке</p>
      <p>{"comment"}</p>
    </div>
  );
};
export default UserRating;
