import React, { useState } from "react";
import { dialogRate } from "../../api/dialog";
import { darkblue } from "../../constants/Constants";

export const Star = ({
  f = false,
  onClick = () => {},
  forceUpdate = () => {}
}) => {
  return (
    <div
      onClick={() => {
        onClick();
        forceUpdate();
      }}
      className={f ? "Star star-filled" : "Star star"}
    ></div>
  );
};

function useForceUpdate() {
  const [value, setValue] = useState(true);
  return () => setValue(!value);
}

function Stars({ starsArr = [], changeFilledStars = () => {} }) {
  const forceUpdate = useForceUpdate();
  return (
    <div className="Stars">
      <p className="tip">Пожалуйста, оцените ответ эксперта</p>
      <div className="starsBlock">
        {starsArr.map((s, index) => {
          const { fill } = s;
          return (
            <Star
              forceUpdate={forceUpdate}
              onClick={() => changeFilledStars(index)}
              f={fill}
              key={index}
            ></Star>
          );
        })}
      </div>
    </div>
  );
}

const ModalDialog = ({ dialogId, closeModal, closeDialog }) => {
  var initialState = [
    { fill: true },
    { fill: true },
    { fill: true },
    { fill: true },
    { fill: true }
  ];
  const [starsArr, changeStars] = useState(initialState);
  const [comment, changeComment] = useState("");
  function changeFilledStars(index) {
    var buf = starsArr;
    index++;
    for (var i = 0; i < index; i++) buf[i]["fill"] = true;
    for (i = index; i < buf.length; i++) buf[i]["fill"] = false;
    changeStars(buf);
  }

  function getStarsCount() {
    var i = 0;

    while (starsArr[i].fill !== false) {
      if (i === starsArr.length - 1) return i + 1;
      i++;
    }
    return i;
  }

  return (
    <div className="ModalDialog">
      <div className="close-button" onClick={closeModal}></div>
      <h3>Оценка диалога</h3>
      <Stars starsArr={starsArr} changeFilledStars={changeFilledStars}></Stars>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <textarea
          style={{ borderColor: darkblue }}
          className="input"
          placeholder="Ваш отзыв"
          onChange={e => changeComment(e.target.value)}
        ></textarea>
      </div>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <button
          className="btn"
          onClick={() => {
            const userRating = getStarsCount();
            const userRatingComment = comment;
            closeDialog(result => {
              console.log("Результат закрытия", result);
            });
            dialogRate(
              {
                dialogId: parseInt(dialogId),
                userRating: parseInt(userRating),
                userRatingComment: userRatingComment
              },
              result => {
                console.log(result);
              }
            );
            closeModal();
          }}
        >
          Завершить диалог
        </button>
      </div>
      {/* <button>Закрыть без оценки</button> */}
    </div>
  );
};
export default ModalDialog;
