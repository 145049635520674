const initialState = 'check'

export default function preloader (state = initialState, action) {
  if (action.type === '') {
    return [...state]
  } else if (action.type === 'SET_SUCCESS') {
    return 'check'
  } else if (action.type === 'SET_LOADING') {
    return 'loader'
  } else if (action.type === 'SET_FAIL') {
    return 'close-preloader'
  } else if (action.type === 'CLEAR_STATUS') {
    return ''
  }
  return state
}
