import React from "react";
import { Redirect } from "react-router-dom";
import {
  dialogGet,
  dialogSendMessage,
  dialogEdit,
  getFormattedDialogs,
  dialogClose,
} from "../../api/dialog";
import MessageBlock from "../../components/uikit/MessageBlock/MessageBlock";
import Select from "react-select";
import { categoryList } from "../../api/category";
import UserRatingBlock from "../../components/UserRatingBlock";
import FaqElement from "../../components/uikit/FaqElement";
import { faqGet } from "../../api/faq";
import { getMessagesTimer } from "../../constants/Constants";
import { closeDialogSuccess } from "../../language/Dialogs";
export default class CDialogOthers extends React.Component {
  state = {
    id: null,
    category: "",
    categories: [],
    msg: [],
    faqs: [],
    faq: [],
    response: "",
    isClosed: true,
    newCategory: null,
    userRating: null,
    userRatingComment: null,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    const category = this.props.match.params.category.split("_").join(" ");
    this.setState({ id, category });

    dialogGet({ dialogId: id }, ({ results, result }) => {
      if (results !== false && result !== false)
        this.setState({ msg: results });
    });
    this.getDialogInfo(id);
    this.loadCategories(category);
    setInterval(
      () =>
        dialogGet({ dialogId: id }, ({ results, result }) => {
          if (results !== false && result !== false)
            this.setState({ msg: results });
        }),
      getMessagesTimer
    );

    document.title = "Вопросы пользователей";
  }
  getDialogInfo = async (id) => {
    getFormattedDialogs({ dialogId: id, someoneElse: true }, (results) => {
      const dialog = results.find((d, index) => {
        return d.id == id;
      });
      if (typeof dialog !== "undefined") {
        const {
          isClosed,
          status,
          faq,
          category,
          userRating,
          userRatingComment,
        } = dialog;
        this.setState({
          isClosed,
          staus: status.status,
          faq,
          userRating,
          userRatingComment,
        });
        let faqs = [];

        faq.map((fid, index) => {
          faqGet({ id: fid, category: category.id }, ({ result }) => {
            if (result !== false) {
              faqs.push(result);
              this.setState({ faqs });
            }
          });
        });
      }
    });
  };
  loadCategories = (defaultCategory = null) => {
    var options = [];
    categoryList(({ results = [] }) => {
      results.map((category, index) => {
        const { id, title } = category;
        options.push({ value: id, label: title });
        let isDefaultCategory = title === defaultCategory;
        console.log(isDefaultCategory, title, defaultCategory);
        if (isDefaultCategory === true)
          this.setState({ selectedOption: { value: id, label: title } });
        return index;
      });
    });

    this.setState({ categories: options });
  };

  onSend = (e) => {
    const { id, response } = this.state;
    dialogSendMessage(
      {
        dialogId: id,
        text: response,
        isAnswer: true,
      },
      (data) => {}
    );
  };

  handleChange = (e) => {
    this.setState({ response: e.target.value });
  };
  handleChangeSelect = (selectedOption) => {
    const { id } = this.state;
    dialogEdit({ dialogId: id, categoryId: selectedOption.value }, () => {
      this.setState({ selectedOption, newCategory: selectedOption.label });
    });
  };

  closeDialogF = () => {
    const { id } = this.state;
    dialogClose({ dialogId: id }, () => alert(closeDialogSuccess));
    this.getDialogInfo(id);
  };

  render() {
    const {
      categories,
      selectedOption,
      msg,
      newCategory,
      id,
      faqs,
      faq,
      userRating,
      isClosed,
      category,
      userRatingComment,
    } = this.state;
    if (newCategory !== null) {
      // document.location.pathname =
      return (
        <Redirect
          to={"/others/" + newCategory.split(" ").join("_") + "/" + id}
        ></Redirect>
      );
    }
    return (
      <div className="Question">
        <div
          className="DialogHeader"
          style={{
            marginBottom: 10,
          }}
        >
          <h1 className="title h3 m-title without-border mobile-padding ellipsis">
            {typeof msg[0] !== "undefined" ? msg[0].text : null}
          </h1>
          <Select
            name="categories "
            styles={{ width: 300, marginBottom: 10 }}
            value={selectedOption}
            noOptionsMessage={({ inputValue }) => {
              var msg = "Категория " + inputValue + " не найдена";
              return msg;
            }}
            placeholder="Выберите категорию"
            onChange={this.handleChangeSelect}
            className="categories customSelect mobile-w-100 b-r-0 mobile-margin"
            options={categories}
          />
        </div>

        {msg.map((m, index) => {
          const { isAccepted, fromUserType } = m;
          if (isAccepted === false && fromUserType === 2) return;
          return <MessageBlock key={index} msg={m}></MessageBlock>;
        })}
        <UserRatingBlock
          userRating={userRating}
          userRatingComment={userRatingComment}
        ></UserRatingBlock>
        {isClosed === true ? null : (
          <button
            className="dialog-btn btn-dialog-close"
            onClick={this.closeDialogF}
          >
            Закрыть диалог
          </button>
        )}
        {faq.length !== 0 && faqs.length !== 0 ? (
          <>
            <h2 className="AnotherFAQHeader without-border m-title ">
              Чистовые вопросы по данному диалогу
            </h2>
            <div className="AnotherFAQ">
              {faqs.map((FAAAQ, index) => {
                return (
                  <FaqElement
                    key={index}
                    faq={FAAAQ}
                    category={category}
                    id={faq[index]}
                    i={index}
                  ></FaqElement>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}
