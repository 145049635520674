import React from "react";
import { connect } from "react-redux";
import { searchInput } from "../../language/Dialogs";

class Search extends React.Component {
  state = {
    searchText: ""
  };
  handleInputChange = e => {
    this.props.onChangeInput(e.target.value);
  };
  handleSubmit = () => {
    console.log(this.state.searchText);
  };
  render() {
    const { className, style = { borderRadius: 0 } } = this.props;
    return (
      <div className={"Search " + className} style={style}>
        <div className="circle">
          <div className="search"></div>
        </div>
        <input
          type="text"
          placeholder={searchInput}
          onChange={e => {
            this.handleInputChange(e);
            this.props.handleInputChange(e.target.value);
          }}
          value={this.props.searchText}
        ></input>
      </div>
    );
  }
}
export default connect(
  state => ({
    accountType: state.account_type
  }),
  dispatch => ({
    onChangeInput: payload => dispatch({ type: "SET_INPUT_FILTER", payload })
  })
)(Search);
