import React from "react";
import { faqGet } from "../../api/faq";
import { Link } from "react-router-dom";
import { NormsList } from "../../components/Norms/NormsList";

function reverseSting(string = "", separator = " ") {
  string = string.split(separator);
  let length = string.length;
  let finalString = "";
  for (let i = length - 1; i >= 0; i--) {
    if (i !== 0) finalString += string[i] + separator;
    else finalString += string[i];
  }

  return finalString;
}

function formatTime(time = "") {
  time = time.split(" ");
  var date = time[0];
  time = time[1];
  date = reverseSting(date, "-");
  date = date.split("-").join(".");
  return { date, time };
}

const Answer = ({ pre, text }) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="Answer mobile-padding">
      <span>{pre}</span> {text}
    </div>
  );
};
const Question = ({ pre, text }) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="Question mobile-padding">
      <span>{pre}</span> {text}
    </div>
  );
};

const Title = ({
  title,
  category = "Здесь могла быть ваша категория",
  createTime,
}) => {
  const { date, time } = createTime;
  return (
    <div className="Title ">
      {/* <h1>{title}</h1> */}
      <h1 className="category h3 m-title ">
        <Link
          to="/faq"
          className="DialogTitle h1 darkblue m-title without-border"
        >
          <div className="caret-down"></div> {category}
        </Link>
      </h1>
      <div className="iconBlock mobile-w-100 mobile-padding">
        <div className="block">
          <div className="time"></div>
          <div className="text">{time}</div>
        </div>
        <div className="block">
          <div className="calendar"></div>
          <div className="text">{date}</div>
        </div>
      </div>
    </div>
  );
};

export default class FAQ extends React.Component {
  state = {
    id: null,
    answer: "",
    question: "",
    rule: "",
    createTime: "",
    category: "",
    norms: [],
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;
    this.setState({ id, category: category.split("_").join(" ") });
    this.getFAQ(id);
    document.title = "Часто задаваемые вопросы";
  }
  getFAQ = (id) => {
    faqGet({ id }, ({ result }) => {
      if (result !== false) {
        const { question, answer, rule, createTime, norms } = result;
        this.setState({ question, answer, rule, createTime, norms });
      }
      console.log(result);
    });
  };
  render() {
    const { id, answer, question, rule, createTime, category, norms } =
      this.state;
    return (
      <div className="FAQ">
        <Title
          category={"Часто задаваемые вопросы"}
          createTime={formatTime(createTime)}
        ></Title>
        <div className="content">
          <Question text={question} pre={"Вопрос:"}></Question>
          <Answer text={answer} pre={"Ответ:"}></Answer>
        </div>
        <NormsList norms={norms} isExpert={false} />
      </div>
    );
  }
}
