import React from "react";
import { text } from "../../language/users";
import UsersTable from "../../components/Users/UsersTable";
import { getUsers } from "../../api/admin";
import Paginator from "../../components/uikit/Paginator";

import { connect } from "react-redux";
import { categoryEdit } from "../../api/category";

const countRowOnPage = 10;

class Controllers extends React.Component {
  state = {
    users: [],
    userTypeId: 1,
    pageCount: 0,
    currentPage: 1,
  };
  componentDidMount() {
    this.setUsers();
    document.title = "Контролеры";
  }
  setUsers = () => {
    var users = [];

    getUsers({ userTypeId: 3 }, ({ results = [] }) => {
      users = results.reverse();
    });
    var pageCount;
    if (users.length !== 0)
      pageCount = Math.ceil(users.length / countRowOnPage);
    console.log(users);

    this.props.setUsers(users);
    this.setState({ pageCount });
  };

  changePage = (page) => {
    this.setState({ currentPage: page });
  };
  render() {
    const curPage = this.state.currentPage - 1;
    return (
      <div className="Users">
        <h1 className="m-title without-border">Контролеры</h1>
        <UsersTable
          users={this.props.users.slice(
            curPage * countRowOnPage,
            curPage * countRowOnPage + countRowOnPage
          )}
          currentPage={this.state.currentPage}
        ></UsersTable>
        <Paginator
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          changePage={this.changePage}
        ></Paginator>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (data) => dispatch({ type: "SET_USERS", payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controllers);
