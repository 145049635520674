import React, { useState, useEffect } from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import $ from "jquery";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import Dialog from "../../containers/user/Dialog";
import AllDialogs from "../../containers/user/AllDialogs";
import Users from "../../containers/admin/Users";
import User from "../../containers/admin/User";
import AddUser from "../../containers/admin/AddUser";
import FAQs from "../../containers/user/FAQs";
import FAQ from "../../containers/user/FAQ";
import CreateQuestion from "../../containers/user/CreateQuestion";
import Questions from "../../containers/expert/Questions";
import Question from "../../containers/expert/Question";
import EAllDialogs from "../../containers/expert/EAllDialogs";
import EDialog from "../../containers/expert/EDialog";
import Help from "../../containers/common/Help";
import CQuestions from "../../containers/controller/CQuestions";
import CQuestion from "../../containers/controller/CQuestion";
import CDialogs from "../../containers/controller/CDialogs";
import CDialog from "../../containers/controller/CDialog";
import QsForCheck from "../../containers/controller/QsForCheck";
import QForCheck from "../../containers/controller/QForCheck";
import Experts from "../../containers/controller/Experts";
import AExperts from "../../containers/admin/Experts";
import Controllers from "../../containers/admin/Controllers";
import CreateFAQ from "../../containers/expert/CreateFAQ";
import Login from "../../containers/common/Login";
import history from "../../history";
import { createPublicKey } from "crypto";
import ClickOutside from "./ClickOutside";
import EFAQ from "../../containers/expert/EFAQ";
import NotFound from "../../containers/NotFound";
import CDialogsOthers from "../../containers/controller/CDialogsOthers";
import CDialogOthers from "../../containers/controller/CDialogOthers";
import Circle from "./Circle";
import { counters, isValid } from "../../constants/Constants";
import ExpertStatistic from "../../containers/admin/ExpertStatistic";
import Operators from "../../containers/admin/Operators";
const Apps = {
  user: {
    navLinks: [
      { to: "/newQuestion", text: "Задать вопрос", className: "plus" },
      {
        to: "/dialog",
        text: "Мои диалоги",
        className: "conversation",
        counters: [counters.user],
      },
      {
        to: "/faq",
        text: "Часто задаваемые вопросы",
        className: "faq",
      },
      { to: "/help", text: "Руководство", className: "info" },
    ],
    screens: () => {
      return (
        <Switch>
          <Route exact path="/help" component={Help}></Route>
          <Route exact path="/faq" component={FAQs}></Route>
          <Route path="/faq/:category/:id" component={FAQ}></Route>
          <Route exact path="/dialog" component={AllDialogs}></Route>
          <Route exact path="/dialog/:category/:id" component={Dialog}></Route>
          <Route exact path="/newQuestion/" component={CreateQuestion}></Route>

          <Route exact path="*" component={AllDialogs}></Route>
        </Switch>
      );
    },
  },

  admin: {
    navLinks: [
      { to: "/users", text: "Пользователи", className: "man" },
      { to: "/experts", text: "Эксперты", className: "hat" },
      { to: "/controllers", text: "Контроллеры", className: "tie" },
      { to: "/operators", text: "Операторы", className: "operator" },
      {
        to: "/addUser",
        text: "Добавление нового пользователя",
        className: "add-user",
      },
      {
        to: "/faq",
        text: "Часто задаваемые вопросы",
        className: "faq",
      },
      { to: "/help", text: "Руководство", className: "info" },
      {
        to: "/statistic",
        text: "Статистика по экспертам",
        className: "help",
      },
    ],
    screens: () => {
      return (
        <Switch>
          <Route exact path="/help" component={Help}></Route>
          <Route expert path="/users/:id" component={User}></Route>
          <Route exact path="/experts" component={AExperts}></Route>
          <Route exact path="/users" component={Users}></Route>
          <Route exact path="/operators" component={Operators}></Route>
          <Route exact path="/controllers" component={Controllers}></Route>
          <Route exact path="/statistic" component={ExpertStatistic}></Route>
          <Route exact path="/faq" component={FAQs}></Route>
          <Route expert path="/faq/:category/:id" component={EFAQ}></Route>
          <Route exact path="/addUser" component={AddUser}></Route>
          <Route exact path="*" component={Users}></Route>
          {/* <Route component={Users}></Route> */}
        </Switch>
      );
    },
  },

  operator: {
    navLinks: [
      { to: "/users", text: "Пользователи", className: "man" },
      {
        to: "/addUser",
        text: "Добавление нового пользователя",
        className: "add-user",
      },
      {
        to: "/faq",
        text: "Часто задаваемые вопросы",
        className: "faq",
      },
      { to: "/help", text: "Руководство", className: "info" },
    ],
    screens: () => {
      return (
        <Switch>
          <Route exact path="/help" component={Help}></Route>
          <Route exact path="/users" component={Users}></Route>
          <Route path="/users/:id" component={User}></Route>
          <Route exact path="/faq" component={FAQs}></Route>
          <Route path="/faq/:category/:id" component={EFAQ}></Route>
          <Route exact path="/addUser" component={AddUser}></Route>
          <Route exact path="*" component={Users}></Route>
        </Switch>
      );
    },
  },
  expert: {
    navLinks: [
      {
        to: "/question",
        text: "Вопросы пользователей",
        className: "help",
        counters: [counters.qOverdue, counters.qAll],
      },
      {
        to: "/dialog",
        text: "Мои диалоги",
        className: "conversation",
        counters: [counters.expertAll, counters.expertOverdue],
      },
      {
        to: "/faq",
        text: "Часто задаваемые вопросы",
        className: "faq",
      },
      { to: "/help", text: "Руководство", className: "info" },
    ],
    screens: () => {
      return (
        <Switch>
          <Route exact path="/help" component={Help}></Route>
          <Route exact path="/createFAQ/:id" component={CreateFAQ}></Route>
          <Route exact path="/faq" component={FAQs}></Route>
          <Route exact path="/faq/:category/:id" component={EFAQ}></Route>
          <Route exact path="/question" component={Questions}></Route>
          <Route
            exact
            path="/question/:category/:id"
            component={Question}
          ></Route>
          <Route exact path="/dialog" component={EAllDialogs}></Route>
          <Route exact path="/dialog/:category/:id" component={EDialog}></Route>
          <Route exact path="*" component={EAllDialogs}></Route>
        </Switch>
      );
    },
  },
  controller: {
    navLinks: [
      {
        to: "/question",
        text: "Вопросы пользователей",
        className: "help",
        counters: [counters.qOverdueController, counters.qAllController],
      },
      {
        to: "/dialog",
        text: "Диалоги с экспертами",
        className: "conversation",
      },
      {
        to: "/others",
        text: "Чужие диалоги",
        className: "observation",
        counters: [
          counters.controllerOverdueOthers,
          counters.controllerAllOthers,
        ],
      },
      {
        to: "/check",
        text: "Ответы на проверку",
        className: "answers",
        counters: [
          counters.controllerOverdueForCheck,
          counters.controllerAllForCheck,
        ],
        // count:"controllerList"
      },
      // {
      //   to: "/experts",
      //   text: "Эксперты",
      //   className: "hat"
      // },

      {
        to: "/faq",
        text: "Часто задаваемые вопросы",
        className: "faq",
      },
      { to: "/help", text: "Руководство", className: "info" },
    ],
    screens: () => {
      return (
        <Switch>
          <Route exact path="/help" component={Help}></Route>
          <Route exact path="/faq" component={FAQs}></Route>
          <Route exact path="/faq/:category/:id" component={EFAQ}></Route>
          <Route exact path="/check" component={QsForCheck}></Route>
          <Route
            exact
            path="/check/:category/:id"
            component={QForCheck}
          ></Route>
          <Route exact path="/question" component={Questions}></Route>
          <Route
            exact
            path="/question/:category/:id"
            component={CQuestion}
          ></Route>
          <Route exact path="/others" component={CDialogsOthers}></Route>
          <Route
            exact
            path="/others/:category/:id"
            component={CDialogOthers}
          ></Route>
          <Route exact path="/dialog" component={CDialogs}></Route>
          <Route exact path="/dialog/:category/:id" component={CDialog}></Route>
          <Route exact path="*" component={CDialogs}></Route>
        </Switch>
      );
    },
  },
  error: {
    navLinks: [],
    screens: () => {
      return <Redirect to="/login" />;
    },
  },
};
const types = ["user", "expert", "controller", "admin", "operator"];

const SideMenu = ({ userType, clearFilter, onClickItem }) => {
  const [expanded, setExpanded] = useState(true);

  const typeCode = localStorage.getItem("userType") - 1;
  const type = typeCode === -1 ? "error" : types[typeCode];

  useEffect(() => {
    const w = window.innerWidth;
    if (w <= 767) {
      setExpanded(false);
    }
  }, []);
  // const typeCode = localStorage.getItem('userType') - 1

  useEffect(() => {
    if (window.screen.width >= 768) {
      const openPadding = {
        user: 266,
        expert: 266,
        controller: 266,
        admin: 302,
        operator: 302,
      };
      const padding = openPadding[type];
      console.log(padding);
      if (expanded === false) {
        $("body").css("padding-left", "64px");
        $(".header-bar").css("padding-left", "122px");
        $("main").css("padding-left", "122px");
      } else {
        $("body").css("padding-left", padding + "px");
        $(".header-bar").css("padding-left", "22px");
        $("main").css("padding-left", "22px");
      }
    }
  }, [expanded, type]);
  return (
    <React.Fragment>
      <ClickOutside
        onClickOutside={() => {
          setExpanded(false);
        }}
      >
        <SideNav
          onSelect={(selected) => {
            history.push(selected);
            setExpanded(false);
            clearFilter();
          }}
          expanded={expanded}
          onToggle={(exp) => {
            setExpanded(exp);
          }}
        >
          <Toggle className="d-none d-md-block SideNavToggle" />
          <Toggle className="d-block d-md-none SideNavToggle" />
          <Nav defaultSelected={history.location.pathname}>
            {Apps[type].navLinks.map((navItem, index) => {
              const { to, text, className, counters } = navItem;

              return (
                <NavItem
                  navitemClassName={"NavItem"}
                  subnavClassName="SubNavItem"
                  onSelect={onClickItem}
                  key={index}
                  eventKey={to}
                  selected={history.location.pathname}
                >
                  <NavIcon>
                    <div className={className}></div>
                    <div
                      className="circle-container"
                      ref={(c) => {
                        if (isValid(c) == true) {
                          c.style.setProperty(
                            "background-color",
                            "transparent",
                            "important"
                          );
                        }
                      }}
                    >
                      {isValid(counters) === true
                        ? counters.map((c, index) => {
                            const { id, isImportant } = c;
                            return (
                              <Circle
                                key={index}
                                id={id}
                                isImportant={isImportant}
                              ></Circle>
                            );
                          })
                        : null}
                    </div>
                  </NavIcon>
                  <NavText>{text}</NavText>
                </NavItem>
              );
            })}
          </Nav>
        </SideNav>
      </ClickOutside>
      <main className="">
        <div className="row container">
          <Switch>{Apps[type].screens()}</Switch>
        </div>
      </main>
    </React.Fragment>
  );
};

export default connect(
  (state) => ({ type: state.account_type }),
  (dispatch) => ({
    onLogin: (payload) => dispatch({ type: "SET_ACCOUNT_TYPE", payload }),
    clearFilter: () => dispatch({ type: "CLEAR_FILTERS" }),
  })
)(SideMenu);
