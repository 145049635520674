import $ from "jquery";
import { store } from "../App";

// Авторизация
export async function login(searchParams, success, error) {
  var url = "https://consult.ab-dpo.ru/api/auth/login/";
  // var xhr = new XMLHttpRequest()
  // xhr.open('POST', url, true)

  // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  // xhr.onreadystatechange = function () {
  //   if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
  //     console.log('RESPONSE', xhr.response, xhr.responseText)
  //   }
  // }
  // xhr.send(getParams(searchParams))
  await $.ajax({
    url: url,
    type: "post",
    data: getParams(searchParams),
    crossDomain: true,
    xhrFields: { withCredentials: true },
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
    success: async function (data) {
      console.info("login", data);
      if (data.result === false) {
        store.dispatch({ type: "SET_FAIL" });
        error();
        return;
      }

      localStorage.setItem("isLogin", data.result);
      localStorage.setItem("userType", data.userType);
      if (success && data.userType) {
        await success(data.userType);
        store.dispatch({ type: "SET_SUCCESS" });
        window.location.replace("/");
      }
      // window.location.reload();
    },
  }).catch((err, error, e) => {
    store.dispatch({ type: "SET_FAIL" });
    console.log(err, error, e);
  });
}

// Выход из аккаунта
export async function logout(success) {
  var url = "https://consult.ab-dpo.ru/api/auth/logout/";
  await $.ajax({
    url: url,
    type: "post",
    crossDomain: true,
    xhrFields: { withCredentials: true },
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    success: async function (data) {
      store.dispatch({ type: "SET_SUCCESS" });
      await localStorage.setItem("isLogin", false);
      await localStorage.setItem("userType", 0);
      await localStorage.setItem("login", "");
      document.location.reload();
      document.location.pathname = "/login";
      console.info("Вышел", localStorage);
      if (success) await success();
    },
  });
}

// Восстановление пароля
export function passwordRecovery(data) {
  var url = "https://consult.ab-dpo.ru/api/auth/passwordRecovery/";
  $.ajax({
    url: url,
    type: "post",
    data: getParams(data),
    crossDomain: true,
    xhrFields: { withCredentials: true },
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    dataType: "json",
    beforeSend: () => store.dispatch({ type: "SET_LOADING" }),
    success: (res) => {
      store.dispatch({ type: "SET_SUCCESS" });
    },
    error: function (err) {
      store.dispatch({ type: "SET_FAIL" });
    },
  });
}

function getParams(params) {
  return Object.keys(params)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}
