import React from "react";
import { text } from "../../language/users";
import UsersTable from "../../components/Users/UsersTable";
import { getUsers } from "../../api/admin";
import Paginator from "../../components/uikit/Paginator";

import { connect } from "react-redux";
import { categoryEdit } from "../../api/category";
const countRowOnPage = 10;

class Users extends React.Component {
  state = {
    users: [],
    userTypeId: 1,
    pageCount: 0,
    currentPage: 1,
  };
  componentDidMount() {
    const prohibited = ["experts", "controllers", "operators"];
    const { pathname } = document.location;

    if (!prohibited.includes(pathname)) {
      this.setUsers();
    }

    document.title = "Список пользователей";
  }
  setUsers = () => {
    let type = 1;
    getUsers({ userTypeId: type }, ({ results = [] }) => {
      var pageCount = 0;
      if (results !== false && results)
        pageCount = Math.ceil(results.length / countRowOnPage);
      this.props.setUsers(results.reverse());
      this.setState({ pageCount: pageCount });
    });
  };

  changePage = (page) => {
    this.setState({ currentPage: page });
  };
  render() {
    const curPage = this.state.currentPage - 1;
    return (
      <div className="Users">
        <h1 className="m-title without-border">Пользователи</h1>
        <UsersTable
          users={this.props.users.slice(
            curPage * countRowOnPage,
            curPage * countRowOnPage + countRowOnPage
          )}
          currentPage={this.state.currentPage}
        ></UsersTable>
        <Paginator
          pageCount={this.state.pageCount}
          currentPage={this.state.currentPage}
          changePage={this.changePage}
        ></Paginator>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.filter((user) => user.userTypeId === 1),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (data) => dispatch({ type: "SET_USERS", payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
