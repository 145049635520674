import { getUsers } from '../api/admin'

const initialState = []

export default function users (state = initialState, action) {
  if (action.type === 'SET_USERS') {
    const { payload } = action
    if (typeof payload == 'undefined' || payload.result == 'false') {
      return []
    } else {
      return [...payload]
    }
  } else if (action.type === 'UPDATE_USERS') {
    var users = []
    getUsers({ userTypeId: '' }, ({ results }) => {
      users = results
    })
    return [...users]
  }
  return state
}
