import React from 'react'
import Search from '../../components/uikit/Search'
import Table from '../../components/uikit/DialogTable/Table'
import { getFormattedDialogs } from '../../api/dialog'
import EmptyMessage from '../../components/uikit/EmptyMessage'
import { connect } from 'react-redux'
import FiltersBlock from '../../components/uikit/FiltersBlock'
import { getMessagesTimer } from '../../constants/Constants'

class CDialogs extends React.Component {
  state = {
    data: []
  }
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.filters !== this.props.filters) {
      this.loadDialogs()
    }
  }
  loadDialogs = () => {
    const newFilter = this.props.filters
    newFilter.available = false
    newFilter.isController = false
    newFilter.someoneElse = null
    getFormattedDialogs(newFilter, data => {
      console.log(data)
      if (data && data !== false)
        this.setState({
          data: data
        })
    })
  }
  componentDidMount () {
    this.loadDialogs()
    setInterval(() => this.loadDialogs(), getMessagesTimer)
    document.title = 'Мои диалоги'
  }

  render () {
    return (
      <div className='AllDialogs'>
        <div className='TitleRow mobile-w-100'>
          <h1 className='m-title mobile-w-100'>Мои диалоги</h1>
          <FiltersBlock></FiltersBlock>
          {/* <Search className="mobile-w-100"></Search> */}
        </div>
        {this.state.data.length === 0 ? (
          <EmptyMessage message={'Нет диалогов с экспертами'}></EmptyMessage>
        ) : (
          <Table
            data={this.state.data}
            headers={[
              { title: 'Статус', tip: 'Подсказка', colSpan: 1 },
              { title: '', tip: null, colSpan: 1 },
              { title: 'Категория', tip: null, colSpan: 1 },
              { title: '', tip: null, colSpan: 1 }
            ]}
            to={'/dialog/'}
          ></Table>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  filters: state.filters
})
const mapDispatchToProps = dispatch => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(CDialogs)
