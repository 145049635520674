import React from "react";
import { addUser, listUserTypes } from "../../api/admin";
import { text } from "../../language/users";

import { RadioGroup, Radio } from "react-radio-group";
import Select from "react-select";
import { categoryList } from "../../api/category";
import EditCategory from "../../components/uikit/EditCategory";

export default class AddUser extends React.Component {
  componentDidMount() {
    document.title = "Добавение аккаунта";
  }

  constructor(props) {
    super(props);
    this.state = {
      isHiddenForm: true,
      selectedOption: "",
      categories: [],
      user: {
        email: "",
        firstName: "",
        userTypeId: 1,
      },
      listUserTypes: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    listUserTypes({}, ({ results = [] }) => {
      this.setState({
        listUserTypes: results,
      });
      console.log(results);
    });
  }
  componentDidMount() {
    categoryList(({ results = [] }) => {
      var options = [];
      results.map((category, index) => {
        const { id, title } = category;
        options.push({ value: id, label: title });
        return index;
      });
      this.setState({ categories: options });
    });
    // document.addEventListener("keydown", this.keydownHandler.bind(this));
  }
  handleChangeSelect = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    this.setState({ selectedOption, isHiddenForm: false });
  };
  handleChange(fieldName, event) {
    var user = this.state.user;
    user[fieldName] = event.target.value;
    this.setState({ user });
  }
  handleChangeRadio(fieldName, value) {
    const bufUser = { ...this.state.user };
    bufUser[fieldName] = value;

    const isHiddenForm = !(value === 2 || value === 3);

    this.setState({
      user: bufUser,
      isHiddenForm,
    });
  }

  handleSubmit(event) {
    var newUser = this.state.user;
    const { userTypeId } = this.state.user;
    if (userTypeId === 2 || userTypeId === 3) {
      newUser.categories = this.state.selectedOption
        .map((opt) => opt.value)
        .join(",");
    }

    addUser(newUser, ({ result }) => {
      const { userTypeId } = this.state.user;
      let to = "/users";
      if (result === true) {
        if (userTypeId != 1) {
          to = "/experts";
        }
        alert("Новый пользователь успешно создан!");
        document.location.replace(to);
      }
      console.log(result);
    });
    event.preventDefault();
  }

  isOperator = localStorage.getItem("userType") == 5;

  render() {
    const { selectedOption, categories, user, listUserTypes, isHiddenForm } =
      this.state;
    return (
      <div className="AddUser">
        <h1 className="m-title">{text.addUserTitle}</h1>
        <form className="mobile-padding" onSubmit={this.handleSubmit}>
          <label>
            <p>{text.email}</p>
            <input
              inputMode="email"
              type="text"
              className="mobile-w-100"
              placeholder="example@mail.ru"
              value={user["email"]}
              onChange={(event) => this.handleChange("email", event)}
            />
          </label>
          <label>
            <p> {text.FIO}</p>
            <input
              value={user["firstName"]}
              type="text"
              className="mobile-w-100"
              placeholder="Имя пользователя"
              onChange={(event) => this.handleChange("firstName", event)}
            />
          </label>
          <div>
            <div> {text.type}</div>
            <div className="RadioGroup">
              {listUserTypes.map(({ id, title }, index) => {
                const { userTypeId } = user;
                const isChecked = userTypeId == id;
                return (
                  <label
                    style={{ marginLeft: index == 0 ? 0 : "inherit" }}
                    key={id}
                    for={"opt" + id}
                    class="radio"
                    onClick={() => this.handleChangeRadio("userTypeId", id)}
                  >
                    <input
                      type="radio"
                      id={"opt" + id}
                      value={id}
                      class="hidden"
                      checked={isChecked}
                    />
                    <span class="label"></span>
                    {title}
                  </label>
                );
              })}
            </div>
          </div>
          {isHiddenForm ? null : (
            <div>
              <Select
                name="categories"
                value={selectedOption}
                noOptionsMessage={({ inputValue }) => {
                  let msg = "Категория " + inputValue + " не найдена";
                  return msg;
                }}
                placeholder="Выберите категорию"
                onChange={this.handleChangeSelect}
                className="categories"
                options={categories}
                isMulti={true}
              />
            </div>
          )}
          <input
            type="submit"
            className={"mobile-w-100"}
            value="Создать пользователя"
          />
        </form>
        {this.isOperator ? null : (
          <EditCategory categories={categories}></EditCategory>
        )}
      </div>
    );
  }
}
