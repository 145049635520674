import React from 'react'

import { connect } from 'react-redux'
import { categoryList } from '../../api/category'

class FiltersBlock extends React.Component {
  state = {
    categories: []
  }
  componentDidMount () {
    this.loadCagories()
  }
  loadCagories = () => {
    categoryList(
      ({ results }) => {
        console.log(results, 'categories')

        if (results !== false) this.setState({ categories: results })
      },
      { available: true }
    )
  }
  render () {
    const { categories = [] } = this.state
    const { setFilter, clearFilter, questions, filters } = this.props
    const { isClosed, categoryId, orderByNew } = filters
    return (
      <div className='FiltersBlock mobile-padding'>
        <div className='categories'>
          {categories.map((category, index) => {
            const { title, id } = category
            return (
              <div
                key={index}
                className={
                  id === categoryId
                    ? 'category selected f-btn'
                    : 'category f-btn'
                }
                onClick={() =>
                  setFilter({
                    name: 'categoryId',
                    value: id === categoryId ? null : id
                  })
                }
              >
                {title}
              </div>
            )
          })}
        </div>
        {questions ? null : (
          <div className='filterBlock'>
            Показывать
            <div
              onClick={() => {
                let value
                //Если любые, то показать закрытые
                if (isClosed == null) {
                  value = true
                }
                //Если закрытые, то показать открытые
                if (isClosed == true) {
                  value = false
                }
                //Если открытые, то показать все
                if (isClosed == false) {
                  value = null
                }
                setFilter({ name: 'isClosed', value })
              }}
              className={
                isClosed === true ? 'isClosed true f-btn' : 'isClosed f-btn'
              }
            >
              {isClosed === true
                ? 'закрытые'
                : isClosed === null
                ? 'любые'
                : 'открытые'}
            </div>
            диалоги
          </div>
        )}

        <div className='filterBlock'>
          Сначала
          <div
            onClick={() =>
              setFilter({ name: 'orderByNew', value: !orderByNew })
            }
            className={
              orderByNew === true || orderByNew === null
                ? 'orderByNew f-btn true'
                : 'orderByNew f-btn'
            }
          >
            {orderByNew === true || orderByNew === null ? 'новые' : 'старые'}
          </div>
          <div className='clearFilters f-btn' onClick={() => clearFilter()}>
            Очистить фильтры
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.filters
})
const mapDispatchToProps = dispatch => {
  return {
    setFilter: ({ name, value }) =>
      dispatch({ type: 'SET_FILTER', name, value }),
    clearFilter: () => dispatch({ type: 'CLEAR_FILTERS' })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FiltersBlock)
