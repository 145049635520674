export const text = {
  title: "Создание вопроса",
  chooseCategory: "Выберите категорию",
  question: "Вопрос",
  cTip:"Пожалуйста выберите категорию",
  qTip:
    "Сформулируйте Ваш вопрос в специальном поле. Вам также доступно форматирование текста и прикрепление ссылки к вопросу. ",
  placeholder: "Введите Ваш вопрос",
  edit: "Форматирование текста",
  send: "Отправить",
  similarQuestions: "Похожие вопросы",
  anotherQuestion: "Тут могут быть вопросы по Вашей теме",
};
