import { dialogList } from "./dialog";
import { counters, isValid } from "../constants/Constants";
import $ from "jquery";
const commonRequest = async (body, counter) => {
  body.dialogCount = true;
  dialogList(body, ({ result }) => {
    if (isValid(counter)) {
      if (result != false) {
        counter.innerHTML = result;
      }
    }
  });
};

export const userDialogCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    isRead: false,
    isNotRead: true,
    categoryId: null,
    orderByNew: true,
    isController: false,
  };
  const { id } = counters.user;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

export const qAllCount = async () => {
  const body = {
    available: true,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: false,
    isController: false,
    someoneElse: true,
    overdue: false,
  };
  const { id } = counters.qAll;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

//TODO: СДЕЛАТЬ ПОСЛЕ API, когда будет флаг о том просроченли диалог
export const qOverdueCount = async () => {
  const body = {
    available: true,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: false,
    isController: false,
    someoneElse: true,
    overdue: true,
  };
  const { id } = counters.qOverdue;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

export const qAllCountController = async () => {
  const body = {
    available: true,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: false,
    isController: false,
    someoneElse: true,
    overdue: false,
  };
  const { id } = counters.qAllController;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

export const qOverdueCountController = async () => {
  const body = {
    available: true,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: false,
    isController: false,
    someoneElse: true,
    overdue: true,
  };
  const { id } = counters.qOverdueController;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

export const expertAllCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: true,
    isController: false,
    overdue: false,
  };
  const { id } = counters.expertAll;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};
export const expertOverdueCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: true,
    overdue: true,
    isController: false,
  };
  const { id } = counters.expertOverdue;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

export const controllerAllOthersCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: true,
    isController: false,
    overdue: false,
    someoneElse: true,
  };
  const { id } = counters.controllerAllOthers;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};
export const controllerOverdueOthersCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: true,
    overdue: true,
    isController: false,
    someoneElse: true,
  };
  const { id } = counters.controllerOverdueOthers;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};

export const controllerAllForCheckCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: true,
    overdue: false,
    isController: true,
  };
  const { id } = counters.controllerAllForCheck;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};
export const controllerOverdueForCheckCount = async () => {
  const body = {
    available: false,
    count: 100,
    offset: 0,
    isClosed: null,
    userRating: null,
    dialogId: null,
    categoryId: null,
    orderByNew: true,
    overdue: true,
    isController: true,
  };
  const { id } = counters.controllerOverdueForCheck;

  const counter = $("#" + id)[0];

  commonRequest(body, counter);
};
