import React from "react";
import { Row } from "./Row";
import { TableHead } from "./TableHead";
import Paginator from "../Paginator";
import { isValid } from "../../../constants/Constants";

export default class Table extends React.Component {
  elementsCount = 10;

  mobileWidth = 768;

  state = {
    data: [],
    i: 0,
    pageCount: 0,
    isMobile: window.innerWidth < this.mobileWidth,
  };

  handleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < this.mobileWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  changePage = (page) => {
    this.setState({ i: page - 1 });
  };
  render() {
    const { isMobile, i } = this.state;
    const { style, data } = this.props;

    // if (isMobile) return <div>Мобилка</div>;
    return (
      <div>
        <table className="DialogsTable" style={style ? style : {}}>
          {/* <TableHead headers={this.props.headers}></TableHead> */}
          <tbody>
            {!data && data !== false
              ? null
              : data
                  .slice(
                    i * this.elementsCount,
                    i * this.elementsCount + this.elementsCount
                  )
                  .map(
                    (
                      {
                        id,
                        status = null,
                        text,
                        category,
                        lastUserMessageTime,
                        question,
                        isRead,
                        isClosed,
                        havingAnswerFromExpert,
                        lastUserMessageType,
                        dialogCreateTime,
                        isBetweenControllerAndExpert,
                        overdue,
                      },
                      index
                    ) => {
                      var label_title = "";

                      if (isValid(category)) {
                        label_title = category.hasOwnProperty("title")
                          ? category.title
                          : category.label;
                      }

                      return (
                        <Row
                          key={index}
                          index={index}
                          status={status}
                          text={text}
                          havingAnswerFromExpert={havingAnswerFromExpert}
                          isRead={isRead}
                          question={question}
                          category={category}
                          isMobile={isMobile}
                          isClosed={isClosed}
                          isBetweenControllerAndExpert={
                            isBetweenControllerAndExpert
                          }
                          overdue={overdue}
                          to={
                            category
                              ? this.props.to +
                                label_title.split(" ").join("_") +
                                "/" +
                                id
                              : this.props.to + "/" + id
                          }
                          dialogCreateTime={dialogCreateTime}
                          lastUserMessageTime={lastUserMessageTime}
                          lastUserMessageType={lastUserMessageType}
                        ></Row>
                      );
                    }
                  )}
          </tbody>
        </table>
        <Paginator
          currentPage={this.state.i + 1}
          changePage={this.changePage}
          pageCount={Math.ceil(data.length / 10)}
        ></Paginator>
      </div>
    );
  }
}
