import React, { useState } from "react";
import MessageBlock from "../../components/uikit/MessageBlock/MessageBlock";
import {
  dialogSendMessage,
  dialogGet,
  dialogClose,
  dialogList,
  getFormattedDialogs,
} from "../../api/dialog";

import FaqElement from "../../components/uikit/FaqElement";
import { Link } from "react-router-dom";
import { faqGet } from "../../api/faq";
import UserRatingBlock from "../../components/UserRatingBlock";
import TextAreaBlock from "../../components/uikit/MessageBlock/TextAreaBlock";
import { getMessagesTimer, isValid } from "../../constants/Constants";
import useModal from "use-react-modal";
import { closeDialog } from "../../language/Dialogs";
import ModalDialog from "../../components/uikit/ModalDialog";

const ModalMenu = ({ id, closeDialogF, answerNo }) => {
  const { isOpen, openModal, closeModal, Modal } = useModal({
    background: "#33333333",
  });

  const [isHide, hide] = useState(false);

  return (
    <div
      className="question-for-user"
      style={{ top: isHide === false ? 40 : -100 }}
    >
      <div className="question-block">
        <div className="button-row">
          <button onClick={openModal} className="yes">
            {"Эксперт молодец"}
          </button>
          <button onClick={() => hide(true)} className="no">
            {"Нет"}
          </button>
        </div>
      </div>
      {isOpen && (
        <Modal>
          <ModalDialog
            closeDialog={closeDialogF}
            closeModal={closeModal}
            dialogId={id}
          ></ModalDialog>
        </Modal>
      )}
    </div>
  );
};

export default class Dialog extends React.Component {
  state = {
    id: null,
    msgs: [],
    id: null,
    response: "",
    isClose: false,
    status: null,
    category: "",
    faq: [],
    faqs: [],
    userRating: null,
    userRatingComment: null,
    userType: null,
    havingAnswerFromExpert: false,
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;

    const userType = localStorage.getItem("userType");

    this.setState({ id, category, userType });
    this.loadMessages(id);
    this.getDialogInfo(id);
    setInterval(() => this.loadMessages(id), getMessagesTimer);
    document.title = "Мои диалоги";
  }

  getDialogInfo = async (id) => {
    let dialog;
    // dialogList({ dialogId: id }, ({ results }) => {
    //   dialog = results[0];
    // });
    // if (typeof dialog !== "undefined") {
    //   const {
    //     isClosed,
    //     status = null,
    //     dialogId = null,
    //     faq = [],
    //     userRating = 0,
    //     userRatingComment = "",
    //   } = dialog;

    //   this.setState({
    //     isClose: isClosed,
    //     status: status.status,
    //     faq,
    //     userRating,
    //     userRatingComment,
    //   });
    //   var faqs = [];
    //   faq.map((fid, index) => {
    //     faqGet({ id: fid, category: dialog.category.id }, ({ result }) => {
    //       if (result !== false) {
    //         faqs.push(result);
    //       }
    //       this.setState({ faqs });
    //     });
    //   });
    // }
    getFormattedDialogs({}, (results = []) => {
      const dialog = results.find((dialog, index) => {
        return dialog.id == id;
      });
      if (typeof dialog !== "undefined") {
        const {
          isClosed,
          status = null,
          dialogId = null,
          faq = [],
          userRating = null,
          userRatingComment = null,
          havingAnswerFromExpert,
        } = dialog;

        console.log(dialog);

        this.setState({
          isClose: isClosed,
          status: status.status,
          faq,
          userRating,
          userRatingComment,
          havingAnswerFromExpert,
        });
        var faqs = [];
        faq.map((fid, index) => {
          faqGet({ id: fid, category: dialog.category.id }, ({ result }) => {
            if (result !== false) {
              faqs.push(result);
            }
            this.setState({ faqs });
          });
        });
      }
    });
  };
  loadMessages = (id) => {
    dialogGet({ dialogId: id }, ({ results = [] }) => {
      this.setState({ msgs: results });
    });
  };

  onSend = (e) => {
    const { id, response } = this.state;

    dialogSendMessage(
      {
        dialogId: id,
        text: response,
      },
      (data) => {
        this.loadMessages(id);
      }
    );
    this.setState({ response: "" });
  };

  closeDialogF = (success) => {
    const { id } = this.state;
    dialogClose({ dialogId: id }, success);
    this.getDialogInfo(id);
  };

  handleChange = (e) => {
    let { value } = e.target;
    const { response } = this.state;
    let newStrLength = response.length + value.length;
    if (newStrLength > 10000) {
      value = value.substr(0, 10000);
    }
    this.setState({ response: value });
  };
  render() {
    const {
      msgs,
      status,
      faq,
      category,
      isClose,
      faqs,
      userRating,
      response,
      userRatingComment,
      userType,
      havingAnswerFromExpert,
    } = this.state;
    const { id } = this.props.match.params;

    const style = { border: 0 + " !important" };

    return (
      <div className="Dialog">
        <h1 className="mobile-padding m-title">
          <Link to="/dialog"> 
            <div className="caret-down"></div> {"Мои диалоги"}
          </Link>
        </h1>
        <div className="Messages">
          {msgs.map((msg, index) => {
            return <MessageBlock key={index} msg={msg}></MessageBlock>;
          })}
        </div>

        {isValid(userType) == false ? null : userType == 1 &&
          havingAnswerFromExpert == true &&
          isClose == false ? (
          <ModalMenu
            closeDialogF={this.closeDialogF}
            id={id}
          ></ModalMenu>
        ) : null}
        {this.state.isClose === true ? null : (
          <TextAreaBlock
            onSend={this.onSend}
            handleChange={this.handleChange}
            value={response}
            closeDialog={this.closeDialogF}
            id={id}
            showButtons={havingAnswerFromExpert}
          ></TextAreaBlock>
        )}
        <UserRatingBlock
          userRating={userRating}
          userRatingComment={userRatingComment}
        ></UserRatingBlock>

        {faq.length !== 0 || !faq || faq === null ? (
          <>
            <h2 className="AnotherFAQHeader without-border m-title ">
              Чистовые вопросы по данному диалогу
            </h2>
            <div className="AnotherFAQ">
              {faqs.map((FAAAQ, index) => {
                return (
                  <FaqElement
                    key={index}
                    faq={FAAAQ}
                    category={category}
                    id={faq[index]}
                    i={index}
                  ></FaqElement>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}
