import React from "react";
import { isValid } from "../../constants/Constants";

const Circle = ({ isImportant = false, id = null }) => {
  return (
    <div
      ref={(c) => {
        if (isValid(c) == true)
          c.style.setProperty("background-color", "transparent", "important");
      }}
      style={{ backgroundColor: "transparent !important" }}
      className={isImportant == true ? "circle red" : "circle"}
      id={id}
    ></div>
  );
};

export default Circle;
