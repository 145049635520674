import React, { useState, useEffect } from "react";
import { loginScreen } from "../../language/Texts";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../api/common";

const ButtonBlock = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("zxfasac@mail.ru");
  // const [password, setPassword] = useState("VqIK1RiG");
  const [error, setError] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      const { keyCode } = e;

      if (keyCode == 13) {
        setError(false);
        login(
          { email, password },
          (res) => {
            localStorage.setItem("login", email);
            onLogin(res);
          },
          (err) => {
            console.log(err);
            setError(true);
          }
        );
      }
    });
  });
  return (
    <div
      className="ButtonBlock"
      onKeyDown={(e) => {
        console.log(e.keyCode);
      }}
    >
      <input
        placeholder="example@mail.com"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      ></input>
      <input
        placeholder="Пароль"
        type="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></input>
      <Link
        className="loginButton"
        onClick={(e) => {
          e.preventDefault();
          setError(false);
          login(
            { email, password },
            (res) => {
              localStorage.setItem("login", email);
              onLogin(res);
            },
            (err) => {
              console.log(err);
              setError(true);
            }
          );
        }}
        to="/"
      >
        Войти
      </Link>
      {error === true ? (
        <div className="error-login mobile-padding">
          Не удалось войти, проверьте логин и/или пароль
        </div>
      ) : null}
    </div>
  );
};
class Login extends React.Component {
  componentDidMount() {
    document.title = "Авторизация";
  }

  render() {
    const { overLine, underLine } = loginScreen;
    return (
      <div className="Login container">
        <div className="content">
          <div className="overLine">
            <h1 className="h3">
              {overLine.header.regular}
              <p>{overLine.header.bold}</p>
            </h1>
            {overLine.lines.map((line, index) => {
              return (
                <p key={index} className="line">
                  {line.regular}
                </p>
              );
            })}
          </div>
          <div className="underLine">
            <p>{underLine.regular}</p>
            {/* {this.props.data.map((user, index) => {
              return <p key={index}> {JSON.stringify(user)}</p>;
            })} */}
            <ButtonBlock onLogin={this.props.onLogin}></ButtonBlock>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({ data: state.account_type }),
  (dispatch) => ({
    onLogin: (payload) => dispatch({ type: "SET_ACCOUNT_TYPE", payload }),
  })
)(Login);
