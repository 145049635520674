import React from "react";

import "./style.scss";

export const ExpertStatisticRow = ({
  expert = {
    closed_count: "",
    dialog_count: "",
    placeholder: "",
    firstName: "",
    email: "",
    categories: [],
  },
}) => {
  const { firstName, dialog_count, closed_count } = expert;

  const percent = parseInt(
    (parseInt(closed_count) / parseInt(dialog_count)) * 100
  );

  return (
    <div className="statistic-row">
      <span className="first-name">{firstName}</span>
      <span className="dialog-count gray">
        <span>
          Вопросов&nbsp; <b className="color-text"> {dialog_count}</b>{" "}
        </span>
        &nbsp;из них{" "}
        <span className="accent-text bold">
          <b>&nbsp;{closed_count}&nbsp;</b>
        </span>
        – <span className="gray">&nbsp;&nbsp;{percent}%&nbsp;&nbsp;</span>{" "}
        закрыто
      </span>
    </div>
  );
};
