import React from 'react'
import { Redirect } from 'react-router-dom'
import { dialogGet, dialogSendMessage, dialogEdit } from '../../api/dialog'
import MessageBlock from '../../components/uikit/MessageBlock/MessageBlock'
import DialogHeader from '../../components/uikit/DialogHeader'
import Select from 'react-select'
import { categoryList } from '../../api/category'
import CMessageBlock from '../../components/uikit/MessageBlock/CMessageBlock'
import { getMessagesTimer } from '../../constants/Constants'

export default class CQuestion extends React.Component {
  state = {
    id: null,
    category: '',
    categories: [],
    msg: {},
    response: '',
    newCategory: null
  }
  componentDidMount () {
    console.log('Mounted')

    const { id } = this.props.match.params
    const category = this.props.match.params.category.split('_').join(' ')
    this.setState({ id, category })
    dialogGet({ dialogId: id }, ({ results, result }) => {
      console.log(results, result)
      if (results !== false && result !== false)
        this.setState({ msg: results[0] })
    })
    setInterval(
      () =>
        dialogGet({ dialogId: id }, ({ results, result }) => {
          console.log(results, result)
          if (results !== false && result !== false)
            this.setState({ msg: results[0] })
        }),
        getMessagesTimer
    )

    this.loadCategories(category)
    document.title = 'Вопросы пользователей'
  }
  loadCategories = (defaultCategory = null) => {
    var options = []
    categoryList(({ results = [] }) => {
      results.map((category, index) => {
        const { id, title } = category
        options.push({ value: id, label: title })
        let isDefaultCategory = title === defaultCategory
        console.log(isDefaultCategory, title, defaultCategory)
        if (isDefaultCategory === true)
          this.setState({ selectedOption: { value: id, label: title } })
        return index
      })
    })

    this.setState({ categories: options })
  }
  onSend = e => {
    const { id, response } = this.state
    dialogSendMessage(
      {
        dialogId: id,
        text: response,
        isAnswer: true
      },
      data => {}
    )
  }

  handleChange = e => {
    this.setState({ response: e.target.value })
  }
  handleChangeSelect = selectedOption => {
    const { id } = this.state
    dialogEdit({ dialogId: id, categoryId: selectedOption.value }, () => {
      this.setState({ selectedOption, newCategory: selectedOption.label })
    })
  }

  render () {
    const { categories, selectedOption, msg, newCategory, id } = this.state
    if (newCategory !== null) {
      document.location.reload()
      return (
        <Redirect
          to={'/question/' + newCategory.split(' ').join('_') + '/' + id}
        ></Redirect>
      )
    }
    return (
      <div className='Question'>
        <div
          className='DialogHeader'
          style={{
            marginBottom: 10
          }}
        >
          <h1 className='title h3 m-title without-border mobile-padding ellipsis'>
            {msg.text}
          </h1>
          <Select
            name='categories '
            styles={{ width: 300, marginBottom: 10 }}
            value={selectedOption}
            noOptionsMessage={({ inputValue }) => {
              var msg = 'Категория ' + inputValue + ' не найдена'
              return msg
            }}
            placeholder='Выберите категорию'
            onChange={this.handleChangeSelect}
            className='categories customSelect mobile-w-100 b-r-0 mobile-margin'
            options={categories}
          />
        </div>

        {typeof msg.text !== 'undefined' ? (
          <MessageBlock msg={msg}></MessageBlock>
        ) : null}
      </div>
    )
  }
}
