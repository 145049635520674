import React from "react";
import Find from "./Find";

import { withRouter } from "react-router-dom";

const HeaderWithHide = props => {
  const { location } = props;

  if (location.pathname.match(/login/)) {
    return null;
  }
  return (
    <div className="header">
      <div className="logo-block">
        <div className="left-thing" />
        <div className="container">
          <div className="row nowrap" style={{ alignItems: "center" }}>
            <div className="logo-width">
              <h2 className="logo-text mb-0">онлайн консультация</h2>
            </div>
            <Find />
          </div>
        </div>
      </div>
    </div>
  );
};
const Header = withRouter(HeaderWithHide);
export default Header;
