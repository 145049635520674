import React from "react";
import { Link, Redirect } from "react-router-dom";
import { text } from "../../language/newQ";
import Select from "react-select";
import { categoryList } from "../../api/category";
import { dialogSendMessage } from "../../api/dialog";
import Table from "../../components/uikit/DialogTable/Table";
import { faqList, faqGet } from "../../api/faq";
import { gray } from "../../constants/Constants";

export default class CreateQuestion extends React.Component {
  state = {
    data: [],
    filteredData: [],
    selectedOption: "",
    categories: [],
    question: "",
    isHiddenForm: true,
    redirect: false,
  };

  getFAQs = async (category) => {
    const { value, title } = category;
    faqList({ categoryId: value }, ({ results = [] }) => {
      if (results !== false) {
        results = results.map((faq) => ({ ...faq, category: category }));
        this.setState({
          data: results,
          filteredData: results,
        });
        console.log("FAQS", results);
      } else {
        this.setState({ data: [], filteredData: [] });
      }
    });
  };
  componentDidMount() {
    categoryList(({ results = [] }) => {
      var options = [];
      results.map((category, index) => {
        const { id, title } = category;
        options.push({ value: id, label: title });
        return index;
      });
      this.setState({ categories: options });
    });
    document.addEventListener("keydown", this.keydownHandler.bind(this));
    document.title = "Создания вопроса";
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption, isHiddenForm: false });
    if (selectedOption) {
      console.log(`Selected: ${selectedOption.label}`);
    }
    this.getFAQs(selectedOption);
  };
  keydownHandler(e) {
    if (
      e.keyCode === 13 &&
      e.ctrlKey &&
      this.state.isHiddenForm === false &&
      this.state.question !== ""
    ) {
      this.sendQ(e);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownHandler);
  }
  handleChangeText = (e) => {
    let { value } = e.target;
    this.setState({ question: value });
    var bufData = [...this.state.data];
    var wordsArr = e.target.value.split(" ");
    const fltData = [];
    bufData.forEach((data, index) => {
      var matchesCount = 0;
      wordsArr.forEach((word, i) => {
        if (data.question.indexOf(word) != -1) matchesCount++;
      });
      if (matchesCount) fltData.push({ data, matchesCount });
    });
    fltData.sort((a, b) => {
      return b.matchesCount - a.matchesCount;
    });
    this.setState({
      filteredData: fltData.map((data) => data.data),
    });
  };

  sendQ = (e) => {
    e.preventDefault();
    dialogSendMessage(
      {
        categoryId: this.state.selectedOption.value,
        text: this.state.question,
      },
      ({ result }) => {
        if (result !== false) {
          this.setState({ redirect: true });
        }
      }
    );
  };
  render() {
    const { selectedOption, categories, data, filteredData } = this.state;
    const {
      chooseCategory,
      title,
      qTip,
      anotherQuestion,
      question,
      similarQuestions,
      send,
      edit,
      cTip,
      placeholder,
    } = text;
    return (
      <div className="CreateQuestion">
        {this.state.redirect ? <Redirect push to="/dialog" /> : null}
        <h1 className="mobile-padding m-title">{title}</h1>
        {/* <h5 className="chooseCategory h_undrln mobile-padding">
          {chooseCategory}
        </h5> */}
        <p className="qTip mobile-padding">{cTip}</p>
        <div className="mobile-padding">
          <Select
            name="categories"
            value={selectedOption}
            noOptionsMessage={({ inputValue }) => {
              var msg = "Категория " + inputValue + " не найдена";
              return msg;
            }}
            placeholder="Выберите категорию"
            onChange={this.handleChange}
            className="categories"
            options={categories}
          />
        </div>
        {this.state.isHiddenForm ? null : (
          <div>
            <h5 className="question h_undrln mobile-padding">{question}</h5>
            <p className="qTip mobile-padding">{qTip}</p>
            <div className="mobile-padding TextAreaBlock">
              <textarea
                maxLength={1000}
                className="QArea"
                placeholder={placeholder}
                onChange={this.handleChangeText}
                value={this.state.question}
              />
              <div
                className="lettersCount"
                style={{
                  color:
                    this.state.question.length === 1000 ? "#e74c3c" : "#18567e",
                }}
              >
                {this.state.question.length}/1000
              </div>
            </div>
            <div className="mobile-padding">
              <Link
                to="/dialog"
                className="send mobile-w-100"
                onClick={this.sendQ}
              >
                <div className="letter"></div>
                {send}
              </Link>
            </div>
            <h5 className="similarQuestions h_undrln mobile-padding">
              {similarQuestions}
            </h5>
            {filteredData.length !== 0 ? (
              <Table
                data={filteredData}
                headers={[{ title: "", tip: null, colSpan: 1 }]}
                to={"/faq/"}
              ></Table>
            ) : (
              <div className="mobile-padding">
                <Link to="/faq" className="anotherQuestion">
                  {anotherQuestion}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
