import React from "react";
import Search from "../../components/uikit/Search";
import Table from "../../components/uikit/DialogTable/Table";
import { getFormattedDialogs } from "../../api/dialog";
import EmptyMessage from "../../components/uikit/EmptyMessage";
import { connect } from "react-redux";
import FiltersBlock from "../../components/uikit/FiltersBlock";
import { getMessagesTimer } from "../../constants/Constants";
class EAllDialogs extends React.Component {
  state = {
    data: [],
    filteredData: [],
    searchText: "",
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.filters !== this.props.filters) {
      this.loadDialogs();
    }
  }
  loadDialogs = () => {
    const newFilter = this.props.filters;
    newFilter.available = false;
    getFormattedDialogs(newFilter, (data) => {
      if (data && data !== false)
        this.setState({
          data: data,
        });
    });
  };
  componentDidMount() {
    this.loadDialogs();
    setInterval(() => this.loadDialogs(), getMessagesTimer);

    document.title = "Мои диалоги";
  }

  handleInputChange(value) {
    var bufData = [...this.state.data];
    var wordsArr = value.split(" ");
    const fltData = [];
    bufData.forEach((data, index) => {
      var matchesCount = 0;
      wordsArr.forEach((word, i) => {
        if (data.text.indexOf(word) != -1) matchesCount++;
      });
      if (matchesCount)
        fltData.push({ data: data, matchesCount: matchesCount });
    });
    fltData.sort((a, b) => {
      return b.matchesCount - a.matchesCount;
    });
    this.setState({
      filteredData: fltData.map((data) => data.data),
    });
  }

  render() {
    return (
      <div className="AllDialogs">
        <div className="TitleRow">
          <h1 className="m-title">Мои диалоги</h1>
          <FiltersBlock></FiltersBlock>
        </div>
        {this.state.data.length === 0 ? (
          <EmptyMessage message="Список ваших диалогов пуст"></EmptyMessage>
        ) : (
          <Table
            data={this.state.data}
            headers={[
              { title: "Статус", tip: "Подсказка", colSpan: 1 },
              { title: "", tip: null, colSpan: 1 },
              { title: "Категория", tip: null, colSpan: 1 },
              { title: "", tip: null, colSpan: 1 },
            ]}
            to={"/dialog/"}
          ></Table>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  filters: state.filters,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(EAllDialogs);
