const initialState = {
  isClosed: null,
  categoryId: null,
  orderByNew: true
};

function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

export default function filters(state = initialState, action) {
  if (action.type === "SET_FILTER") {
    const { name, value } = action;
    console.log(action);
    let buf = clone(state);
    buf[name] = value;
    return buf;
  } else if (action.type === "CLEAR_FILTERS") {
    return initialState;
  }
  return state;
}
