export const navLinks = {
  user: [
    { to: "/", text: "Руководство" },
    { to: "/dialog", text: "Мои диалоги" },
    { to: "/faq", text: "Часто задаваемые вопросы" },
    { to: "/login", text: "Выйти" },
  ],
  expert: [
    { to: "/account", text: "Мои диалоги" },
    { to: "/users-questions", text: "Вопросы пользователей" },
    { to: "/dialog", text: "Мои диалоги" },
    { to: "/faq", text: "Часто задаваемые вопросы" },
    { to: "/", text: "Руководство" },
  ],
  controller: [
    { to: "/users-questions", text: "Вопросы пользователей" },
    { to: "/experts-dialogs", text: "Диалоги  с экспертами" },
    { to: "/account", text: "Мои диалоги" },
    { to: "/expert", text: "Эксперты" },
    { to: "/faq", text: "Часто задаваемые вопросы" },
    { to: "/", text: "Руководство" },
  ],
  admin: [
    { to: "/account", text: "Мои диалоги" },
    { to: "/users-questions", text: "Вопросы пользователей" },
    { to: "/dialog", text: "Мои диалоги" },
    { to: "/faq", text: "Часто задаваемые вопросы" },
    { to: "/", text: "Руководство" },
  ],
};

export const loginScreen = {
  overLine: {
    header: {
      regular: "Вас приветствует сервис",
      bold: " Онлайн консультирование",
    },
    lines: [
      { regular: "Мы ответим на Ваши вопросы быстро, вежливо и информативно" },
      {
        regular:
          "После составления вопроса Вы получите ответ в течение 48 часов!",
      },
    ],
    contacts: {
      regular: "Мы в социальных сетях",
      links: [
        { iconName: "facebook.svg", link: "https://facebook.com" },
        { iconName: "vk.svg", link: "https://vk.com" },
      ],
    },
  },
  underLine: {
    regular:
      "Для получения консультации войдите в свой аккаунт, используемый при обучении в УЦ «Академия Безопасности»",
  },
  login: "Войти",
  register: "Пройти регистрацию",
};

export const manualText = {
  user: [
    {
      title: "Как задать вопрос специалисту?",
      text:
        "В меню с левой стороны выбрать вкладку «Задать вопрос», выбрать подходящую категорию для вопроса, ввести вопрос в специальное поле и нажать на кнопку «Отправить». На Ваш электронный адрес поступит письмо- оповещение об успешном создании вопроса.",
    },
    {
      title: "В течение какого времени я получу ответ на вопрос?",
      text:
        "Наш Специалист готовит ответ в течение 48 часов, но не позднее этого срока. Письмо-оповещение поступит на Ваш электронный адрес.",
    },
    {
      title: "Где посмотреть ответ на мой вопрос?",
      text:
        "На Ваш электронный адрес поступит письмо-оповещение. Готовый ответ от Специалиста будет отображаться у Вас в личном кабинете, в разделе «Мои диалоги», при клике на Ваш вопрос, появится блок с ответом.",
    },
    {
      title: "Если по предоставленному ответу возник вопрос-уточнение.",
      text:
        "После того как Вы получили ответ от Специалиста, возникла необходимость что-то уточнить, вот такое уточнение можно задать тут же в разделе «Мои диалоги». Но если Вам необходимо задать новый вопрос, то данный диалог необходимо закрыть и добавить новый вопрос.",
    },
    {
      title: "Что означает кнопка «Завершить диалог», зачем она нужна?",
      text:
        "После того как Вы получили ответ на вопрос и он Вас полностью удовлетворяет, нам необходимо понимать остались ли Вы довольны. Данная кнопка позволяет оставить свои комментарии и оценить работу сервиса. Таким образом Вы даете нам возможность получать Ваши пожелания и воплощать их в жизнь.",
    },
    {
      title: "Раздел «Часто задаваемые вопросы».",
      text:
        "Вся база с ответами наших Специалистов хранится в разделе «Часто задаваемые вопросы». Вы в любое время сможете просмотреть данный раздел, возможно ответ на Ваш вопрос уже прорабатывался Специалистом. Тем самым Вы сэкономите свое время и Вам не придется направлять вопрос.",
    },
  ],
  expert: [
    {
      title: "Помощь в настройке того эксперту",
      text:
        "Таким образом постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет оценить значение существенных финансовых и административных условий. Товарищи! постоянный количественный рост и сфера нашей активности требуют от нас анализа форм развития. Товарищи! укрепление и развитие структуры влечет за собой процесс внедрения и модернизации модели развития.",
    },
    {
      title: "Помощь в настройке этого",
      text:
        "С другой стороны постоянный количественный рост и сфера нашей активности представляет собой интересный эксперимент проверки направлений прогрессивного развития. Задача организации, в особенности же новая модель организационной деятельности в значительной",
    },
  ],
  controller: [
    {
      title: "Помощь в настройке того контроллеру",
      text:
        "Таким образом постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет оценить значение существенных финансовых и административных условий. Товарищи! постоянный количественный рост и сфера нашей активности требуют от нас анализа форм развития. Товарищи! укрепление и развитие структуры влечет за собой процесс внедрения и модернизации модели развития.",
    },
    {
      title: "Помощь в настройке этого",
      text:
        "С другой стороны постоянный количественный рост и сфера нашей активности представляет собой интересный эксперимент проверки направлений прогрессивного развития. Задача организации, в особенности же новая модель организационной деятельности в значительной",
    },
  ],
  admin: [
    {
      title: "Помощь в настройке того админу",
      text:
        "Таким образом постоянный количественный рост и сфера нашей активности в значительной степени обуславливает создание позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что постоянное информационно-пропагандистское обеспечение нашей деятельности позволяет оценить значение существенных финансовых и административных условий. Товарищи! постоянный количественный рост и сфера нашей активности требуют от нас анализа форм развития. Товарищи! укрепление и развитие структуры влечет за собой процесс внедрения и модернизации модели развития.",
    },
    {
      title: "Помощь в настройке этого",
      text:
        "С другой стороны постоянный количественный рост и сфера нашей активности представляет собой интересный эксперимент проверки направлений прогрессивного развития. Задача организации, в особенности же новая модель организационной деятельности в значительной",
    },
  ],
};
