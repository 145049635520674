import React from 'react'
import Select from 'react-select'
import Search from '../../components/uikit/Search'
import Table from '../../components/uikit/DialogTable/Table'
import { faqList, faqGet } from '../../api/faq'
import { categoryList } from '../../api/category'
import { text } from '../../language/FAQs'
import EmptyMessage from '../../components/uikit/EmptyMessage'
import { Link } from 'react-router-dom'
export default class FAQs extends React.Component {
  state = {
    data: [],
    categoryId: 1,
    categories: []
  }
  componentDidMount () {
    this.loadCategories()
    document.title = 'Часто задаваемые вопросы'
  }

  getFAQs = async category => {
    const { id, title } = category
    faqList({ categoryId: id }, ({ results = [] }) => {
      console.log('FAQS', results)
      if (results !== false) {
        results = results.map(faq => ({ ...faq, category: category }))
        this.setState({ data: results })
      } else {
        this.setState({ data: [] })
      }
    })
  }
  loadCategories = () => {
    var options = []
    categoryList(({ results = [] }) => {
      results.map((category, index) => {
        const { id, title } = category
        if (index === 0) this.getFAQs(category)
        options.push({ value: id, label: title })
        return index
      })
    })
    this.setState({ categories: options, selectedOption: options[0] })
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption })
    if (selectedOption) {
      this.getFAQs({ id: selectedOption.value, title: selectedOption.label })
      console.log(`Selected: ${selectedOption.label}`)
    }
  }

  render () {
    const { categories, selectedOption, data } = this.state
    return (
      <div className='FAQs'>
        <div className='TitleRow'>
          <h1 className='m-title'>Часто задаваемые вопросы</h1>
          <Select
            name='categories'
            value={selectedOption}
            noOptionsMessage={({ inputValue }) => {
              var msg = 'Категория ' + inputValue + ' не найдена'
              return msg
            }}
            placeholder='Выберите категорию'
            onChange={this.handleChange}
            className='categories mobile-w-100'
            options={categories}
          />
        </div>
        {localStorage.getItem('userType') == 1 ? (
          <div className='addYourQ'>
            Не нашли ответ?
            <Link className='href' to='/newQuestion'>
              Задайте свой вопрос!
            </Link>
          </div>
        ) : null}
        {data.length === 0 ? (
          <EmptyMessage message={text.empty}></EmptyMessage>
        ) : (
          <Table
            data={data}
            headers={[{ title: '', tip: null, colSpan: 1 }]}
            to={'/faq/'}
          ></Table>
        )}
      </div>
    )
  }
}
