import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from 'react'
import { animateScroll } from 'react-scroll'

const Messages = forwardRef((props, ref) => {
  // Передаем функцию scrollToBottom по ссылке
  const [childrenCount, setChildrenCount] = useState(0)
  let messageBlock
  let scrollToBottom = () => {
    const el = messageBlock
    if (el) {
      animateScroll.scrollToBottom({
        containerId: 'MessageBlock'
      })
    }
  }
  useImperativeHandle(ref, () => ({
    scroll () {
      scrollToBottom()
    }
  }))
  useEffect(() => {
    scrollToBottom()
  })
  const { children } = props
  if (childrenCount !== children.length) setChildrenCount(children.length)
  return (
    <div
      className='Messages'
      ref={ref => (messageBlock = ref)}
      id='MessageBlock'
    >
      {children}
    </div>
  )
})
export default Messages
