import React from "react";
import { NormItem } from "./NormItem";

export const NormsList = ({
  norms,
  isExpert = false,
  isEditMode = false,
  actualize = () => {},
}) => {
  if (norms.length == 0) {
    return null;
  }
  return (
    <div className="norms-list">
      <div className="norms-list__title">
        Нормы, на основании которых дан ответ
      </div>
      {norms.map((norm) => (
        <NormItem
          norm={norm}
          isEditMode={isEditMode}
          isExpert={isExpert}
          actualize={actualize}
        />
      ))}
    </div>
  );
};
