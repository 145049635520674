import React from "react";
import { Link } from "react-router-dom";
import { getFormattedDialogs, dialogGet } from "../../api/dialog";
import MessageBlock from "../../components/uikit/MessageBlock/MessageBlock";
import { text } from "../../language/FAQs";
import { faqAdd } from "../../api/faq";
import {
  createContentSKK,
  getAdditionalPartsForNorm,
  getAllNorms,
} from "../../api/norms";
import Button from "../../components/uikit/Button";
import { NormalButton } from "../../components/uikit/NormalButton";
import { accentDark, green } from "../../constants/Constants";

const TextArea = ({ text, onChange, title }) => {
  const status = text === "" ? "red" : "green";
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="TextArea mobile-margin">
      <p className="title">{title}</p>
      <textarea value={text} className={status} onChange={onChange}></textarea>
    </div>
  );
};
const Rule = ({ text, onChange, title }) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  const status = text === "" ? "red" : "green";
  return (
    <div className="TextArea mobile-margin">
      <p className="title">{title}</p>
      <input value={text} className={status} onChange={onChange}></input>
    </div>
  );
};
class CreateFAQ extends React.Component {
  state = {
    id: null,
    msgs: [],
    question: "",
    answer: "",
    categoryId: null,
    rule: "",
    errorMessage: "",
    allNorms: [],
    additionalParts: [],
    newAdditionalPart: [],
    selectedMainParts: [],
    selectedAdditionalParts: [],
    isCreateSuccess: false,
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;
    this.setState({ id });
    this.loadMessages(id);
    this.getDialogInfo(id);
    this.loadAllNorms();
    document.title = "Создание часто задаваемого вопроса";
  }

  loadAllNorms() {
    getAllNorms({}, this.loadNormsSuccess);
  }

  loadNormsSuccess = (norms) => {
    this.setState({ allNorms: norms });
  };

  loadAdditionalParts = (e, index) => {
    const { value } = e.target;
    const bufMainParts = [...this.state.selectedMainParts];
    bufMainParts[index] = value;
    this.setState({ selectedMainParts: bufMainParts });
    this.fetchAdditionalParts(value, index);
  };

  fetchAdditionalParts = (uuid, index) => {
    const bufAddParts = [...this.state.additionalParts];
    const bufNewAddPart = [...this.state.newAdditionalPart];
    const bufSelectedAddParts = [...this.state.selectedAdditionalParts];

    getAdditionalPartsForNorm({ main_part_uuid: uuid }, (res) => {
      bufAddParts[index] = res;
      bufNewAddPart[index] = "";

      if (res.length > 0) {
        bufSelectedAddParts[index] = res[0].uuid;
      } else {
        bufSelectedAddParts[index] = undefined;
      }
      this.setState({
        additionalParts: bufAddParts,
        newAdditionalPart: bufNewAddPart,
        selectedAdditionalParts: bufSelectedAddParts,
      });
    });
  };

  handleAdditionalPartChange = (e, index) => {
    const { value } = e.target;
    const buf = [...this.state.selectedAdditionalParts];
    buf[index] = value;
    this.setState({ selectedAdditionalParts: buf });
  };

  getDialogInfo = async (id) => {
    getFormattedDialogs({}, (results = []) => {
      const dialog = results.find((dialog, index) => {
        return dialog.id == id;
      });
      if (dialog) {
        const { categoryId } = dialog;
        this.setState({ categoryId });
      }
    });
  };

  loadMessages = (id) => {
    dialogGet({ dialogId: id }, ({ results = [] }) => {
      this.setState({ msgs: results });
      if (results[0]) {
        this.setState({ question: results[0].text });
      }
      if (results[1]) {
        this.setState({ answer: results[1].text });
      }
    });
  };

  handleChangeQ = (e) => {
    this.setState({ question: e.target.value });
  };

  handleChangeA = (e) => {
    this.setState({ answer: e.target.value });
  };

  handleChange = (e, index = null) => {
    const { name, value } = e.target;
    if (index == null) {
      this.setState({ [name]: value });
      return;
    }

    const buf = [...this.state[name]];
    buf[index] = value;
    this.setState({ [name]: buf });
  };

  addMainPart = () => {
    const { allNorms, selectedMainParts } = this.state;
    const { uuid } = allNorms[0];

    this.fetchAdditionalParts(uuid, selectedMainParts.length);
    this.setState({ selectedMainParts: [...selectedMainParts, uuid] });
  };

  create = (e) => {
    e.preventDefault();
    const { question, answer, categoryId, id, rule } = this.state;
    if (question === "" || answer === "") {
      this.setState({ errorMessage: "Пожалуйста, заполните все поля" });
      e.preventDefault();
      return;
    }
    const { norms, text } = this.getFormattedNorms();
    faqAdd(
      {
        question: question,
        answer: answer,
        categoryId: categoryId,
        dialogId: id,
        rule: "1",
        norms: JSON.stringify(norms),
        text: JSON.stringify(text),
      },
      this.handleCreate
    );
  };

  handleCreate = ({ result }) => {
    if (typeof result != "number") {
      alert("Ошибка создания FAQ: " + result);
      return;
    }
    this.setState({ isCreateSuccess: true });
  };

  getFormattedNorms() {
    const {
      selectedMainParts,
      selectedAdditionalParts,
      newAdditionalPart,
      allNorms,
    } = this.state;
    const text = [];
    const norms = selectedMainParts.map((main_part_uuid, index) => {
      const newPart = newAdditionalPart[index];
      const additional_part_uuid = selectedAdditionalParts[index];

      const foundedNorm = allNorms.find((norm) => norm.uuid == main_part_uuid);
      text[index] = foundedNorm ? foundedNorm.name : "";

      const norm = { main_part_uuid };

      const isEmptyNewPart = newPart == "" || typeof newPart == "undefined";
      const isEmptyAddPartUUID =
        additional_part_uuid == "" ||
        typeof additional_part_uuid == "undefined";

      if (isEmptyNewPart && isEmptyAddPartUUID) {
        return { ...norm, additional_part_name: "" };
      }
      if (isEmptyNewPart) {
        return { ...norm, additional_part_uuid };
      }

      return { ...norm, additional_part_name: newPart };
    });
    return { norms, text };
  }

  deleteNorm = (index) => {
    const _state = this.state;
    const bufMain = [..._state.selectedMainParts];
    const bufAdd = [..._state.selectedAdditionalParts];
    const bufNewAddPart = [..._state.newAdditionalPart];

    bufMain.splice(index, 1);
    bufAdd.splice(index, 1);
    bufNewAddPart.splice(index, 1);
    this.setState({
      selectedMainParts: bufMain,
      selectedAdditionalParts: bufAdd,
      newAdditionalPart: bufNewAddPart,
    });
  };

  render() {
    const {
      msgs,
      status,
      id,
      allNorms,
      isCreateSuccess,
      additionalParts,
      newAdditionalPart,
      selectedMainParts,
      selectedAdditionalParts,
    } = this.state;
    const { title, answer, ready, question, rule } = text;
    return (
      <div className="CreateFAQ">
        <h1 className="m-title without-border">{title}</h1>
        <div className="Messages">
          {msgs.map((msg, index) => {
            return <MessageBlock key={index} msg={msg}></MessageBlock>;
          })}
        </div>
        <div className="CreateForm">
          <TextArea
            onChange={this.handleChangeQ}
            text={this.state.question}
            title={question}
          ></TextArea>
          <TextArea
            onChange={this.handleChangeA}
            text={this.state.answer}
            title={answer}
          ></TextArea>
          {/* <Rule
            onChange={this.handleChangeR}
            text={this.state.rule}
            title={rule}
          ></Rule> */}
          {selectedMainParts.map((mainPartUUID, index) => {
            return (
              <>
                <div
                  key={mainPartUUID}
                  style={{ marginTop: 10 }}
                  className="select-label"
                >
                  Нормы
                </div>
                <div className="d-flex">
                  <select
                    style={{ maxWidth: 600 }}
                    value={mainPartUUID}
                    onChange={(e) => this.loadAdditionalParts(e, index)}
                  >
                    {allNorms.map(({ name, uuid }) => (
                      <option value={uuid}>{name}</option>
                    ))}
                  </select>
                  <div
                    onClick={() => this.deleteNorm(index)}
                    style={{
                      padding: 20,
                      cursor: "pointer",
                      color: accentDark,
                    }}
                  >
                    &times;
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ marginRight: 20 }}>
                    <div className="select-label">
                      Своя дополнительная часть
                    </div>
                    <input
                      style={{ minWidth: 300, marginTop: 10, height: 49 }}
                      name="newAdditionalPart"
                      onChange={(e) => this.handleChange(e, index)}
                      value={newAdditionalPart[index]}
                    ></input>
                  </div>
                  {additionalParts[index] ? (
                    additionalParts[index].length > 0 ? (
                      <div>
                        <div className="select-label">Дополнительная часть</div>
                        <select
                          value={selectedAdditionalParts[index]}
                          onChange={(e) =>
                            this.handleAdditionalPartChange(e, index)
                          }
                        >
                          {additionalParts[index].map(({ title, uuid }) => (
                            <option value={uuid}>{title}</option>
                          ))}
                        </select>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </>
            );
          })}

          {this.state.errorMessage === "" ? null : (
            <div className="errorMessage  mobile-padding">
              {this.state.errorMessage}
            </div>
          )}
          <div
            onClick={this.addMainPart}
            className="btn green  b-r-3 mobile-w-100"
          >
            Добавить норму
          </div>
          <div className=" mobile-w-100 mobile-padding d-flex ">
            <Link
              onClick={this.create}
              to="/dialog"
              className="btn  b-r-3 mobile-w-100"
            >
              <span>Создать FAQ</span>
            </Link>
          </div>
          {isCreateSuccess ? (
            <div style={{ marginTop: 20, color: green }}>
              Часто задаваемый вопрос успешно добавлен
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default CreateFAQ;
