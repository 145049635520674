import React from "react";
import { faqGet, faqEdit, faqDelete, faqActualize } from "../../api/faq";
import { categoryList } from "../../api/category";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import { handleInputChange } from "react-select/src/utils";
import { darkblue, green } from "../../constants/Constants";
import { NormsList } from "../../components/Norms/NormsList";
function reverseSting(string = "", separator = " ") {
  string = string.split(separator);
  let length = string.length;
  let finalString = "";
  for (let i = length - 1; i >= 0; i--) {
    if (i !== 0) finalString += string[i] + separator;
    else finalString += string[i];
  }

  return finalString;
}

function formatTime(time = "") {
  time = time.split(" ");
  var date = time[0];
  time = time[1];
  date = reverseSting(date, "-");
  date = date.split("-").join(".");
  return { date, time };
}

const Answer = ({
  pre,
  text,
  handleInputChange = () => {
    console.log("function not loaded");
  },
  saveSuccess = false,
}) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="Answer mobile-padding">
      <span>{pre}</span>
      <textarea
        value={text}
        onChange={(e) => handleInputChange(e.target.value, "answer")}
        style={{ borderColor: saveSuccess === true ? green : darkblue }}
      />
    </div>
  );
};
const Question = ({
  pre,
  text,
  handleInputChange = () => {
    console.log("function not loaded");
  },
  saveSuccess = false,
}) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="Question mobile-padding">
      <span>{pre}</span>
      <textarea
        value={text}
        onChange={(e) => handleInputChange(e.target.value, "question")}
        style={{ borderColor: saveSuccess === true ? green : darkblue }}
      />
    </div>
  );
};

const Title = ({
  title,
  category = "Здесь могла быть ваша категория",
  createTime,
  children,
}) => {
  const { date, time } = createTime;
  return (
    <div className="Title mobile-padding ">
      {/* <h1>{title}</h1> */}
      <h1
        className="category h3 m-title without-border"
        style={{ width: "100%", marginBottom: 10 }}
      >
        <Link
          to="/faq"
          className="DialogTitle h1 darkblue m-title without-border"
        >
          <div className="caret-down"></div> {category}
        </Link>
      </h1>
      {children}
      {/* <h1 className="category h3">{category}</h1> */}
      <div className="iconBlock mobile-w-100">
        <div className="block">
          <div className="time"></div>
          <div className="text">{time}</div>
        </div>
        <div className="block">
          <div className="calendar"></div>
          <div className="text">{date}</div>
        </div>
      </div>
      {/* {children[1]} */}
    </div>
  );
};

export default class EFAQ extends React.Component {
  state = {
    id: null,
    answer: "",
    question: "",
    rule: "",
    createTime: "",
    category: "",
    dialogId: null,
    categories: [],
    saveSuccess: false,
    isChanged: false,
    newCategory: null,
    norms: [],
  };

  doneTyping = () => {
    const { id, question, answer, rule, dialogId, selectedOption } = this.state;

    if (selectedOption)
      faqEdit(
        {
          id: parseInt(id),
          categoryId: selectedOption.value,
          question,
          answer,
          rule,
          dialogId,
        },
        ({ result }) => {
          this.setState({ isChanged: false });
          console.log(result);
        }
      );
  };

  handleInputChange = (text, option) => {
    const buf = this.state;
    buf[option] = text;
    buf.isChanged = true;
    this.setState(buf);
  };
  componentDidMount() {
    const { id, category } = this.props.match.params;
    this.setState({ id, category: category.split("_").join(" ") });
    this.getFAQ(id);
    this.loadCategories(category.split("_").join(" "));
    document.title = "Часто задаваемые вопросы";
  }
  getFAQ = (id) => {
    faqGet({ id }, ({ result }) => {
      if (result !== false) {
        const { question, answer, rule, createTime, dialogId, norms } = result;
        this.setState({ question, answer, rule, createTime, dialogId, norms });
      }
      console.log(result);
    });
  };
  loadCategories = (faqCategory) => {
    var options = [];
    categoryList(({ results = [] }) => {
      results.map((category, index) => {
        const { id, title } = category;
        if (title === faqCategory) {
          this.setState({ selectedOption: { value: id, label: title } });
        }
        options.push({ value: id, label: title });
        return index;
      });
    });
    this.setState({ categories: options });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    const { id, question, answer, rule, dialogId } = this.state;
    if (selectedOption) {
      faqEdit(
        {
          id: parseInt(id),
          categoryId: selectedOption.value,
          question,
          answer,
          rule,
        },
        ({ result }) => {
          console.log(result);
        }
      );
      // this.getFAQs({ id: selectedOption.value, title: selectedOption.label });
      console.log(`Selected: ${selectedOption.label}`);
    }
  };

  handleChangeSelect = (selectedOption) => {
    const { id } = this.state;
    faqEdit(
      {
        id: parseInt(id),
        categoryId: selectedOption.value,
      },
      () => {
        this.setState({ selectedOption, newCategory: selectedOption.label });
      }
    );
  };

  actualizeNorm = (uuid, actual_date) => {
    const { id } = this.state;
    faqActualize({ uuid, actual_date, content_id: id }, this.actualizeSuccess);
  };

  actualizeSuccess = () => {
    const { id } = this.state;
    this.getFAQ(id);
  };

  handleDeleteFAQ = (e) => {
    const isDelete = window.confirm(
      "Вы действительно хотите удалить часто задаваемый вопрос?"
    );

    if (!isDelete) {
      e.preventDefault();
      return;
    }

    const { id } = this.state;
    faqDelete({ id: parseInt(id) }, () => {});
  };

  render() {
    const {
      id,
      answer,
      question,
      rule,
      createTime,
      category,
      categories,
      selectedOption,
      saveSuccess,
      isChanged,
      newCategory,
      norms,
    } = this.state;
    if (newCategory !== null) {
      document.location.reload();
      return (
        <Redirect
          to={"/faq/" + newCategory.split(" ").join("_") + "/" + id}
        ></Redirect>
      );
    }

    return (
      <div className="FAQ EFAQ">
        <Title category={category} createTime={formatTime(createTime)}>
          <Select
            name="categories"
            value={selectedOption}
            noOptionsMessage={({ inputValue }) => {
              var msg = "Категория " + inputValue + " не найдена";
              return msg;
            }}
            placeholder="Выберите категорию"
            onChange={this.handleChangeSelect}
            className="categories mobile-w-100"
            options={categories}
          />
        </Title>
        <div className="content">
          <Question
            text={question}
            handleInputChange={this.handleInputChange}
            pre={"Вопрос:"}
            saveSuccess={saveSuccess}
          ></Question>
          <Answer
            text={answer}
            handleInputChange={this.handleInputChange}
            pre={"Ответ:"}
            saveSuccess={saveSuccess}
          ></Answer>
        </div>
        <NormsList
          norms={norms}
          isExpert={true}
          actualize={this.actualizeNorm}
        />
        <div className="btn-row mobile-padding">
          <Link
            className="delete mobile-w-100"
            to="/faq"
            onClick={this.handleDeleteFAQ}
          >
            Удалить
          </Link>
          {isChanged === true ? (
            <div onClick={this.doneTyping} class="save-btn mobile-w-100">
              Сохранить изменения
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
