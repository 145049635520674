import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";

import "./scss/_main.scss";
import reducer from "./reducers";
import { connect } from "react-redux";

import Login from "./containers/common/Login";
import LeftButtonBlock from "./components/uikit/LeftButtonBlock";
import Header from "./components/uikit/Header";
import HeaderBar from "./components/uikit/HeaderBar";
import Footer from "./components/uikit/Footer";

import SideMenu from "./components/uikit/SideMenu";
import history from "./history";
import Preloader from "./components/uikit/Preloader";
import {
  userDialogCount,
  qAllCount,
  qOverdueCount,
  expertAllCount,
  expertOverdueCount,
  controllerAllForCheckCount,
  controllerOverdueForCheckCount,
  controllerAllOthersCount,
  controllerOverdueOthersCount,
  qAllCountController,
  qOverdueCountController,
} from "./api/counters";
import { getMessagesTimer } from "./constants/Constants";
import thunk from "redux-thunk";

export const store = createStore(
  reducer,
  applyMiddleware(thunk)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const types = ["user", "expert", "controller", "admin"];

class Content extends React.Component {
  render() {
    const typeCode = localStorage.getItem("userType") - 1;
    const type = typeCode === -1 ? "error" : types[typeCode];
    console.log(typeCode, type);
    return (
      <div
        className="container"
        style={{ flexDirection: "row", display: "flex" }}
      >
        {/* <div className="row"> */}
        {/* <LeftButtonBlock navLinks={Apps[type].navLinks}></LeftButtonBlock> */}
        {/* <Route exact component={Login} path="/login"></Route> */}
        {/* {Apps[type].screens()} */}
        {/* </div> */}
      </div>
    );
  }
}

export class App extends React.Component {
  state = { userLogin: "", countHandler: () => {} };
  componentDidMount() {
    const type = localStorage.getItem("userType");
    const { userType } = this.props;
    console.log("App mount", type, userType);
    if (userType == type) {
      if (userType == 4 || userType == 5) {
        console.log("ничего не делаем на маунте");
        return;
      }

      if (userType == 1) {
        this.setIntervalForUsers();
        setInterval(this.setIntervalForUsers, getMessagesTimer);

        this.setState({ countHandler: this.setIntervalForUsers });
        return;
      }
      if (userType == 2) {
        this.setIntervalForExperts();
        setInterval(this.setIntervalForExperts, getMessagesTimer);
        this.setState({ countHandler: this.setIntervalForExperts });
        return;
      }
      if (userType == 3) {
        this.setIntervalForControllers();
        setInterval(this.setIntervalForControllers, getMessagesTimer);
        this.setState({ countHandler: this.setIntervalForControllers });
        return;
      }
    }
  }

  setIntervalForUsers = async () => {
    userDialogCount();
  };

  setIntervalForExperts = async () => {
    qAllCount();
    qOverdueCount();
    expertAllCount();
    expertOverdueCount();
  };

  setIntervalForControllers = async () => {
    qAllCountController();
    qOverdueCountController();
    controllerAllForCheckCount();
    controllerOverdueForCheckCount();
    controllerAllOthersCount();
    controllerOverdueOthersCount();
  };

  render() {
    const { userLogin, countHandler } = this.state;
    const { userType } = this.props;
    return (
      <Provider store={store}>
        <Router history={history} basename="./">
          <HeaderBar />
          {/* <Header /> */}
          <Content></Content>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              render={() => (
                <SideMenu
                  onClickItem={countHandler}
                  userType={userType}
                ></SideMenu>
              )}
            />
          </Switch>
          <Preloader></Preloader>
          <Footer></Footer>
        </Router>
      </Provider>
    );
  }
}
export default connect(
  (state) => ({ type: state.account_type }),
  (dispatch) => ({
    onLogin: (payload) => dispatch({ type: "SET_ACCOUNT_TYPE", payload }),
  })
)(Content);
