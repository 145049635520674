import React from "react";
import ReadMoreReact from "read-more-react";
import { lightblue, orange } from "../../../constants/Constants";

const Avatar = ({ type = 1, name = "" }) => {
  return (
    <div className="avatar">
      {type === 1 ? (
        <div className={"icon question"}>?</div>
      ) : type === 3 ? (
        <div className="circle" style={{ backgroundColor: "#333" }}>
          <div
            style={{ backgroundColor: "#fff", height: 30 }}
            className={"icon tie"}
          ></div>
        </div>
      ) : (
        <div className="circle">
          <div className={"icon hat"}></div>
        </div>
      )}
      {localStorage.getItem("userType") == 3 && type == 2 ? (
        <div className="userName">{name}</div>
      ) : null}
    </div>
  );
};

const Text = ({
  text,
  quotedMessage,
  isAnswer = false,
  isAccepted,
  children,
  accept,
  fromUserType,
}) => {
  var re = "&quot;";
  text = text.split(re).join('"');

  return (
    <div className="text">
      <p
        style={{
          borderRightColor:
            fromUserType === 1
              ? lightblue
              : fromUserType === 2
              ? orange
              : "#1f1f1f",
        }}
        className="message"
      >
        {children}
        {typeof text != "undefined" && text ? (
          <ReadMoreReact
            min={80}
            ideal={200}
            max={500}
            readMoreText="Читать далее..."
            text={text}
          ></ReadMoreReact>
        ) : null}
      </p>
      {isAnswer == true && fromUserType != 3 ? (
        <div className="isAnswer">
          <div className="box">
            <div className="check"></div>
          </div>
          Эксперт отметил данное сообщение, как окончательный ответ
        </div>
      ) : null}
      {/* {typeof quotedMessage !== undefined || quotedMessage === null ? null : (
        <p className="quotedMessage">{quotedMessage.text}</p>
      )} */}
    </div>
  );
};

const Time = ({ time = "" }) => {
  time = time.split(" ");
  time[0] = time[0].split("-");
  time[0] = time[0][2] + "." + time[0][1] + "." + time[0][0];
  return (
    <div className="createTime mobile-w-100">
      <div className="timeRow">
        <div className="time icon"></div>
        <div className="element">{time[1]}</div>
      </div>
      <div className="timeRow">
        <div className="calendar icon"></div>
        <div className="element">{time[0]}</div>
      </div>
    </div>
  );
};

const MessageBlock = ({ msg = {}, accept }) => {
  const {
    id,
    fromUserName,
    createTime,
    isAccepted,
    isAnswer,
    quotedMessage,
    text,
    fromUserType,
  } = msg;

  return (
    <div className="MessageBlock mobile-padding">
      <Text
        text={text}
        isAnswer={isAnswer}
        isAccepted={isAccepted}
        quotedMessage={quotedMessage}
        fromUserType={fromUserType}
        accept={accept}
      >
        <Avatar type={fromUserType} name={fromUserName}></Avatar>
      </Text>
      <Time time={createTime}></Time>
    </div>
  );
};

export default MessageBlock;
